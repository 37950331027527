.section-client {
    width: 100%;
    min-height: 100vh;
    position: relative;
    padding: 40px;
    box-sizing: border-box;

    @media only screen and (max-width: 1024px) {
        padding: 40px 0;
    }

    svg {
        height: 90px;
        margin: auto;
    }

    .container {
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .wrapper {
            width: 100%;

            svg {
                // width: 30%;
                // margin: auto;
                // height: 90px;
                // margin: auto auto 50px;
                display: block;

                @media only screen and (max-width: 576px) {
                    width: 220px;
                }
            }

            h3 {
                color: #927c60;
                display: flex;
                justify-content: center;
                font-size: 1.8em;
                // opacity: 0;
            }

            .container-desktop {
                .grid-inter {
                    display: grid;
                    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
                    grid-template-rows: 100px 100px;
                    width: 60%;
                    // width: 90%;
                    margin: auto;
                    padding-bottom: 20px;

                    @media only screen and (max-width: 1024px) {
                        width: 80%;
                    }

                    >div {
                        position: relative;

                        >img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            height: 50%;
                            // width: auto;
                            // height: 60%;
                            max-width: 130px;

                            @media only screen and (max-width: 768px) {
                                max-width: 80px;
                            }
                        }
                    }

                    .row-1 {
                        grid-row: 1;
                        width: 100%;
                        height: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        // opacity: 0;


                        &.int1 {
                            grid-column: 2;

                        }

                        &.int2 {
                            grid-column: 4;
                        }

                        &.int3 {
                            grid-column: 6;

                        }

                        &.int4 {
                            grid-column: 8;

                        }
                    }

                    .row-2 {
                        grid-row: 2;
                        width: 100%;
                        height: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        // opacity: 0;

                        &.int5 {
                            grid-column: 1;

                        }

                        &.int6 {
                            grid-column: 3;

                        }

                        &.int7 {
                            grid-column: 5;

                        }

                        &.int8 {
                            grid-column: 7;

                        }

                        &.int9 {
                            grid-column: 9;

                            img {
                                max-width: 75px;
                            }

                        }
                    }
                }

                .grid-local {
                    display: grid;
                    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
                    grid-template-rows: 100px 100px 100px;
                    width: 85%;
                    // width: 100%;
                    margin: auto;
                    padding-bottom: 20px;

                    @media only screen and (min-width: 1920px) {
                        width: 70%;
                    }

                    @media only screen and (max-width: 1280px) {
                        width: 90%;
                    }

                    >div {
                        position: relative;

                        >img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: auto;
                            height: 45%;
                            max-width: 90px;

                            @media only screen and (max-width: 768px) {
                                max-width: 65px;
                            }
                        }
                    }

                    .row-1 {
                        grid-row: 1;
                        width: 100%;
                        height: 100%;
                        background-position: center;
                        background-repeat: no-repeat;

                        // opacity: 0;
                        &.int1 {
                            grid-column: 1;

                        }

                        &.int2 {
                            grid-column: 3;

                        }

                        &.int3 {
                            grid-column: 5;

                        }

                        &.int4 {
                            grid-column: 7;

                        }

                        &.int5 {
                            grid-column: 9;

                        }

                        &.int6 {
                            grid-column: 11;

                        }

                        &.int7 {
                            grid-column: 13;

                        }

                        &.int8 {
                            grid-column: 15;

                        }
                    }

                    .row-2 {

                        grid-row: 2;
                        width: 100%;
                        height: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        // opacity: 0;

                        &.int9 {
                            grid-column: 2;

                        }

                        &.int10 {
                            grid-column: 4;

                        }

                        &.int11 {
                            grid-column: 6;

                        }

                        &.int12 {
                            grid-column: 8;

                        }

                        &.int13 {
                            grid-column: 10;

                        }

                        &.int14 {
                            grid-column: 12;

                        }

                        &.int15 {
                            grid-column: 14;

                        }
                    }

                    .row-3 {
                        grid-row: 3;
                        width: 100%;
                        height: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        // opacity: 0;

                        &.int16 {
                            grid-column: 4;

                        }

                        &.int17 {
                            grid-column: 6;

                        }

                        &.int18 {
                            grid-column: 8;

                        }

                        &.int19 {
                            grid-column: 10;

                        }

                        &.int20 {
                            grid-column: 12;

                        }
                    }
                }
            }

            .container-mobile {
                display: none;

                .grid-inter-mobile,
                .grid-local-mobile {
                    display: flex;
                    width: 100vw;
                    margin: auto;
                    box-sizing: border-box;
                    overflow: hidden;
                    padding-bottom: 30px;

                    .container-1,
                    .container-2,
                    .container-3,
                    .container-4 {
                        display: flex;

                        .animate {
                            width: 150px;
                            padding: 0 10px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            transition: all .2s ease;

                            img {
                                width: 100%;
                                max-width: 90px;
                                max-height: 60px;
                            }
                        }
                    }

                    .container-1 {
                        animation: lineSlider 10s linear infinite running;
                        animation-duration: 53s;
                    }

                    .container-2 {
                        animation: lineSlider 20s linear infinite running;
                        animation-duration: 25s;
                    }

                    .container-3 {
                        animation: lineSlider 10s linear infinite running;
                        animation-duration: 40s;

                    }

                    .container-4 {
                        animation: lineSlider 10s linear infinite running;
                        animation-duration: 30s;
                    }


                }
            }


            @media only screen and (max-width: 1024px) {

                .container-desktop {
                    display: none;
                }

                .container-mobile {
                    display: block;
                    // .grid-inter-mobile,
                    // .grid-local-mobile {
                    //     display: flex;
                    //     width: 100vw;
                    //     margin: auto;
                    //     box-sizing: border-box;
                    //     overflow: hidden;
                    //     padding-bottom: 30px;

                    //     .container-1,
                    //     .container-2,
                    //     .container-3,
                    //     .container-4 {
                    //         display: flex;

                    //         .animate {
                    //             width: 150px;
                    //             padding: 0 10px;
                    //             display: inline-flex;
                    //             align-items: center;
                    //             justify-content: center;
                    //             transition: all .2s ease;

                    //             img {
                    //                 width: 100%;
                    //                 max-width: 90px;
                    //                 max-height: 60px;
                    //             }
                    //         }
                    //     }

                    //     .container-1 {
                    //         animation: lineSlider 10s linear infinite running;
                    //         animation-duration: 53s;
                    //     }

                    //     .container-2 {
                    //         animation: lineSlider 20s linear infinite running;
                    //         animation-duration: 25s;
                    //     }

                    //     .container-3 {
                    //         animation: lineSlider 10s linear infinite running;
                    //         animation-duration: 40s;

                    //     }

                    //     .container-4 {
                    //         animation: lineSlider 10s linear infinite running;
                    //         animation-duration: 30s;
                    //     }


                    // }

                }
            }
        }

    }

    .hidden {
        overflow: hidden;

        h3 {
            opacity: 1;
            overflow: hidden;
        }
    }

    @keyframes lineSlider {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(-50%);
        }
    }
}

@media only screen and (max-width: 992px) and (orientation: landscape) {
    .section-client {
        padding: 40px 0;

        svg {
            margin: auto;
            width: 40%;
        }

        .container-desktop {
            display: none;
        }

        .container-mobile {
            display: block !important;
            overflow: hidden;

            h3 {
                font-size: 1.4em !important;
            }
        }
    }
}