@import url("https://use.typekit.net/qbg7hup.css");

* {
  font-family: aw-conqueror-didot, serif;
  font-weight: 700;
  font-style: normal;
  cursor: none !important;
}

body {
  background: transparent;
  /* background: #cfcfcf; */
  width: 100%;
  -webkit-font-smoothing: antialiased;
  cursor: none !important;
  /* height: 100vh; */
  /* overflow-x: hidden; */
  
}

html {
  cursor: none !important;
  background: #000;
}


/* .front-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .front-page {
    position: unset;
    top: unset;
    left: unset;
    width: unset;
  }
} */

.fake-scroll {
  position: absolute;
  top: 0;
  /* width: 1px; */
  width: 10px;
  /* background: red; */
  z-index: 2;
}

/* font-family: gill-sans-nova, sans-serif; */
/* font-family: aw-conqueror-didot, serif; */

li, a, p {
  font-family: gill-sans-nova, sans-serif;
}

li, a, p {
  font-size: 16px;
}

@media only screen and (max-width: 576px) {
  li, a, p {
    font-size: 14px;
  }
}

html {
  overflow-x: hidden;
  /* overflow: scroll;
  overflow-x: hidden; */
}
::-webkit-scrollbar {
    /* width: 0px;
    height: 0px; */
    /* display: none; */
    /* background: transparent;  */
}

::-webkit-scrollbar {
  width: 8px;
  background:rgba(152,152,152,0.3);
}

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(152,152,152,1);  */
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
  background:#927c60;
} 

@media only screen and (max-width: 992px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

/* .front-page {
  height: 100%;
  position: relative;
  overflow: hidden;
  background: #000;
  z-index: 1;
} */

.transparent-space {
  height: 70vh;
  width: 100%;
  pointer-events: none;
  z-index: 1;
  position: relative;
}

.transparent-space img {
  width: 30%;
  display: block;
  margin: auto;
  padding-top: 7vh;
}

@media only screen and (max-width: 576px) {
  .transparent-space img {
    width: 60%;
  }
}

.section-map {
  width: 100%;
  height: 70vh;
  position: fixed;
  bottom: 0;
  /* z-index: -1; */
}

.bodyOver {
  /* background: blue; */
  overflow: hidden !important;
  /* position: relative; */
  position: fixed;
  height: 100%;
}

.htmlOver {
  /* border: 10px solid red; */
  overflow: hidden !important;
}

.rootOver {
  overflow: hidden;
}

video:hover {
  cursor: none !important;
}

/* .App video {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-position: center center;
  background-size: contain;
  object-fit: cover;
  z-index: -1;
  outline: none;
} */