section.section-whyus {
    width: 100%;
    height: 100vh;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 992px) and (orientation: landscape) {
        height: unset;

        h1 {
            font-size: 5em;
        }

        h2 {
            font-size: 1.2em;
        }
    }

    h1 {
        font-size: 6em;
        letter-spacing: 4px;
        margin: 0 0 50px;
    }

    h2 {
        color: #927c60;
        white-space: pre-line;
        letter-spacing: 1.5px;
        margin: 0;
    }

    hr {
        margin: 50px auto;
        width: 0%;
        border: 1px solid #808080;
        opacity: 0;
    }

    p {
        color: #989898;
        font-weight: 300;
        letter-spacing: 1.2px;
        line-height: 1.5em;
        font-size: 14px;
    }

    @media only screen and (max-width: 576px) {
        padding: 60px 20px;

        hr {
            margin: 40px auto;
        }

        p {
            font-size: 12px;
        }
    }

    .container {
        text-align: center;
    }

    .hidden {
        // margin-bottom: 7.5vw;
        // margin-bottom: calc(30*100vw/var(--size));
        overflow: hidden;

        li {
            overflow: hidden;
            opacity: 1;

        }

    }
}