.container-carousel {
  width: 100%;
  display: flex;
  flex-direction: column;

  .slick-next,
  .slick-prev {

    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    opacity: .5;
    transition: opacity .5s ease-out;

    &:hover {
      opacity: 1;
    }

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }

  }

  .slick-next {
    right: 0;

    &:before {
      content: '';
      background: url(/assets/ic-right.svg) center no-repeat;
    }
  }

  .slick-prev {

    left: 0;

    &:before {
      content: '';
      background: url(/assets/ic-left.svg) center no-repeat;
    }
  }

  .img-carousel {
    // max-width: 800px;
    // padding-bottom: 56.25%;
    padding-bottom: 40%;
    height: 0;
    justify-content: center;
    margin: auto;
    position: relative;
    outline: none;

    @media screen and (max-width: 576px) {
      padding-bottom: 80%;
      // height: 50vh;
    }

  }

  .img-carousel-preview {
    // max-width: 800px;
    // padding-bottom: 56.25%;
    padding-bottom: 43%;
    // height: 0;
    justify-content: center;
    margin: auto;
    position: relative;
    outline: none;

    &.video {


      img {
        opacity:0.3;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 15px solid #fff;

        @media screen and (max-width: 768px) {
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 10px solid #fff;
        }
      }

    }

    // @media screen and (max-width: 576px) {
    //   padding-bottom: 80%;
    //   // height: 50vh;
    // }

  }

  .count-element {
    display: block;
    // margin: auto;
  }
}

.slick-slider {
  &:nth-child(1) {
    margin-bottom: 10px;

    .slick-slide {

      div {
        // display: flex;

        img {
          width: 100%;
          position: absolute;
          object-fit: contain;
          height: 100%;
          display: block;
        }

        video {
          z-index: 9;
          outline: none;
          width: 100%;
          position: absolute;
          object-fit: contain;
          height: 100%;
          display: block;
        }
      }
    }
  }

  &:nth-child(2) {
    // .slick-list {
    //   .slick-track {
    //     height: 100px;

    //     > div {
    //       width: 100px !important;
    //     }
    //   }
    // }
    .slick-track {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;

      .slick-slide {
        // filter: grayscale(1);
        transition: opacity .5s ease-out;
        opacity: .3;

        &:hover {
          // filter: grayscale(.5);
          opacity: 1;
        }

        &.slick-active.slick-current {
          // filter: grayscale(0);
          opacity: 1;
        }
      }
    }

    .slick-slide {
      padding: 0 10px;

      // width: 100px !important;
      // height: 100px !important;
      div {
        // display: flex;

        img {
          width: 100%;
          position: absolute;
          object-fit: cover;
          height: 100%;
          display: block;
        }

        video {
          z-index: 9;
          outline: none;
          width: 100%;
          position: absolute;
          object-fit: cover;
          height: 100%;
          display: block;
        }
      }
    }
  }
}

.slick-slide.slick-active.slick-current {
  z-index: 9999 !important;
}