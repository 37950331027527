.section-contact {
    width: 100%;
    height: 80vh;
    position: relative;


    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li,
    a {
        font-size: 14px;
        white-space: nowrap;
        color: #989898;
        font-weight: 400;
        font-family: gill-sans-nova, sans-serif;
        // line-height: 16px;
        letter-spacing: 0.2px;
    }

    a {
        text-decoration: none;
    }

    h2 {
        color: #927c60;
        position: absolute;
        letter-spacing: 3.5px;
        margin: 0;
        text-transform: uppercase;
    }

    .container {
        width: 100%;
        height: 80vh;
        display: flex;

        .wrapper-description {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 60%;
            margin: auto;

            @media only screen and (max-width: 1280px) {
                width: 65%;
            }

            @media only screen and (max-width: 1140px) {
                width: 75%;
            }

            svg {
                width: 100%;
            }

            h2 {
                font-size: 3.5vw;
            }

            .plusCon {
                width: 1vw;
                position: absolute;
                top: -7%;
                left: -3%;
                opacity: 0;
            }

            // .plusFb {
            //     width: 1vw;
            //     position: absolute;
            //     bottom: -37%;
            //     left: 48%;
            //     opacity: 0;
            // }

            @media only screen and (max-width: 992px) {

                li,
                a {
                    font-size: 1.5vw;
                    line-height: 1.8vw;
                }

                h2 {
                    font-size: 30px;
                }
            }

            .new-york,
            .bangkok,
            .facebook {
                position: absolute;
                // width: 100%;
                // height: 100%;
                display: grid;
                grid-template-columns: auto auto;
                grid-template-rows: auto auto auto;
                grid-column-gap: 15px;
            }

            .new-york {
                right: 7vw;
                top: -30px;

                @media only screen and (max-width: 992px) {
                    right: 4vw;
                    top: -25px;
                }

                .plusNy {
                    grid-column: 1;
                    grid-row: 1;
                    width: 1vw;
                    opacity: 0;
                    margin-left: -1vw;
                }

                .nyText {
                    grid-row: 2;

                    &-1 {
                        grid-column: 1;
                        margin: auto 0 auto auto;
                        position: relative;
                        opacity: 0;
                    }

                    &-2 {
                        grid-column: 2;
                        margin: auto auto auto 0;
                        position: relative;
                        opacity: 0;
                    }
                }

                ul.nyContactLeft {
                    grid-row: 3;
                    grid-column: 1;
                    margin: auto 0 auto auto;
                    position: relative;
                    text-align: right;
                }

                ul.nyContactRight {
                    grid-row: 3;
                    grid-column: 2;
                    margin: auto auto auto 0;
                    position: relative;
                    text-align: left;
                }
            }

            .bangkok {
                bottom: -80px;
                left: 20px;

                @media only screen and (max-width: 1280px) {
                    left: -20px;
                }

                @media only screen and (max-width: 992px) {
                    left: -50px;
                    bottom: -55px;

                }

                .bkkText {
                    grid-column: 1/3;
                    grid-row: 2;
                    opacity: 0;
                    // margin: 0 auto;
                    margin: 0 auto 10px;
                    position: relative;
                }

                .plusBkk {
                    width: 1vw;
                    grid-row: 1;
                    grid-column: 1;
                    opacity: 0;
                }

                .bkkContactLeft {
                    grid-row: 3;
                    grid-column: 1;
                    margin-top: 0;
                    position: relative;
                    text-align: right;
                }

                .bkkContactRight {
                    grid-row: 3;
                    grid-column: 2;
                    margin: auto auto auto 0;
                    position: relative;
                }
            }

            .facebook {

                // right: 17vw;
                // bottom: -45%;

                right: 17vw;
                bottom: -100px;

                @media only screen and (max-width: 992px) {
                    right: 15vw;
                    bottom: -90px;
                }

                .plusFb {
                    width: 1vw;
                    grid-column: 1;
                    grid-row: 1;
                    opacity: 0;
                    position: relative;
                    margin-left: -2vw;
                }

                .fbIcon {
                    grid-column: 1;
                    grid-row: 2;
                    opacity: 0;
                    margin-top: auto;
                    position: relative;
                    width: 35px;
                }

                .fbContact {
                    grid-column: 2;
                    grid-row: 2;
                    // opacity: 0;
                    margin: 0 auto;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }



            .container-contact {
                display: flex;
                position: absolute;

                .wrapper {
                    width: 100%;
                    // opacity: 0;
                }

                &.ny {
                    width: 40%;
                    top: 15%;
                    // left: 47%;
                    right: 6.5vw;

                    // @media only screen and (max-width: 1080px) {
                    //     right: 8.5vw;
                    // }
                }

                &.bkk {
                    width: 40%;
                    top: 100%;
                    // left: 6%;
                    left: 1.8vw;

                    // @media only screen and (max-width: 1080px) {
                    //     left: 0vw;
                    // }
                }

                // &.fb {
                //     bottom: -50%;
                //     right: 16vw;
                //     width: 40%;
                //     align-items: center;

                //     .fbContact {
                //         padding-left: 1vw;
                //     }

                //     img {
                //         display: inline-block;
                //         width: 25px;
                //         float: right;
                //         // padding-right: 1vw;
                //     }
                // }


            }
        }

        .wrapper-description-mobile {
            display: none;
            width: 100%;
            // height: 80vh;
            position: relative;
            margin: auto;

            h2 {
                position: relative;
                font-size: 2.5em;
                // font-size: 7vw;
            }

            svg {
                // width: 85%;
                // position: absolute;
                // top: 50%;
                // left: 50%;
                // transform: translate(-50%, -50%);
                width: auto;
                position: absolute;
                top: -40%;
                right: 140px;
                -webkit-transform: translate(-50%, -50%);
                transform: rotate(270deg);
                transform-origin: right top;
                height: 140px;
            }

            .wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                width: 100%;
                padding: 0 20px;
                box-sizing: border-box;

                div.img-contact {
                    img {
                        width: 250px;
                        position: relative;
                        margin: auto;
                        display: block;
                    }
                }

                >div {
                    padding-bottom: 30px;

                    &:last-child {
                        padding-bottom: 0;
                    }
                }

                div.facebook {
                    display: flex;
                    align-items: center;

                    ul {
                        padding-left: 15px;
                    }

                    img {
                        width: 25px;
                        position: relative;
                        margin: auto;
                        display: block;
                    }
                }
            }
        }

        @media only screen and (max-width: 576px) {

            // height: 80vh;

            .wrapper-description {
                display: none;
            }

            .wrapper-description-mobile {
                display: block;
                // width: 100%;
                // // height: 80vh;
                // position: relative;
                // margin: auto;

                // h2 {
                //     position: relative;
                //     font-size: 2.5em;
                //     // font-size: 7vw;
                // }

                // svg {
                //     // width: 85%;
                //     // position: absolute;
                //     // top: 50%;
                //     // left: 50%;
                //     // transform: translate(-50%, -50%);
                //     width: auto;
                //     position: absolute;
                //     top: -40%;
                //     right: 140px;
                //     -webkit-transform: translate(-50%, -50%);
                //     transform: rotate(270deg);
                //     transform-origin: right top;
                //     height: 140px;
                // }

                // .wrapper {
                //     display: flex;
                //     flex-direction: column;
                //     justify-content: space-around;
                //     width: 100%;
                //     padding: 0 20px;
                //     box-sizing: border-box;

                //     div.img-contact {
                //         img {
                //             width: 250px;
                //             position: relative;
                //             margin: auto;
                //             display: block;
                //         }
                //     }

                //     > div {
                //         padding-bottom: 30px;

                //         &:last-child {
                //             padding-bottom: 0;
                //         }
                //     }

                //     div.facebook {
                //         display: flex;
                //         align-items: center;

                //         ul {
                //             padding-left: 15px;
                //         }

                //         img {
                //             width: 25px;
                //             position: relative;
                //             margin: auto;
                //             display: block;
                //         }
                //     }
                // }
            }
        }

        .hidden {
            margin-bottom: 7.5vw;
            margin-bottom: calc(30*100vw/var(--size));
            overflow: hidden;

            li {
                // color: #989898;
                // line-height: 16px;
                // font-weight: 400;
                // font-family: gill-sans-nova, sans-serif;
                // letter-spacing: 0.2px;
                // position: relative;
                overflow: hidden;
                opacity: 1;

            }

        }
    }

}

@media only screen and (max-width: 992px) and (orientation: landscape) {

    .section-contact {
        height: unset;

        .container {
            height: unset;
            padding: 40px 0;

            .wrapper-description {
                display: none;
            }

            .wrapper-description-mobile {
                display: block;
            }
        }
    }
}