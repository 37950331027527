.section-cave {
    width: 100%;
    height: 100%;
    position: relative;

    .container-desktop {

        padding: 80px 0;

        .grid-7 {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            width: 100%;
            max-width: 1500px;
            position: relative;
            margin: auto;


            img {
                width: 100%;
            }

            span {
                border: #927c60 1px solid;

            }

            .sq-1 {
                margin-left: 15%;
                margin-bottom: 30%;
                margin-right: 30%;
                margin-top: -10%;
                grid-row: 1;
                grid-column: 2/6;
            }

            .sq-2 {
                margin-bottom: 60%;
                margin-top: 50%;
                margin-left: 10%;
                grid-row: 1/5;
                grid-column: 10/13;
            }

            .sq-3 {
                grid-row: 5 / 6;
                grid-column: 9 / 13;
                margin-bottom: 20%;
                margin-top: -20%;
                margin-left: 10%;
            }

            .img-office {
                grid-row: 4;
                grid-column: 2;
                margin-top: auto;
                max-width: 45px;
            }

            .img-cave {
                grid-row: 1/5;
                grid-column: 2 / 7;
                margin: auto;
                padding-right: 10px;
            }

            .img-c1 {
                grid-row: 1;
                grid-column: 4/7;
                padding-right: 10px;
                margin-top: 60px;

                @media only screen and (max-width: 1024px) {
                    margin-top: 40px;
                }
            }

            .img-2 {
                grid-row: 1 /2;
                grid-column: 7/10;
                padding-left: 10px;
                margin-bottom: 10px;
            }

            .img-3 {
                grid-row: 4;
                grid-column: 3/7;
                margin-top: auto;
                padding-right: 10px;
            }

            .img-4 {
                grid-row: 4;
                grid-column: 7/12;
                padding-left: 10px;
                margin-top: 10px;
            }

            .img-5 {
                grid-row: 5;
                grid-column: 5 / 9;
                margin-top: 20px;
            }
        }

    }

}

.section-cave-mobile, .page-title {
    display: none;
}

@media only screen and (max-width: 576px) {
    .section-cave {
        display: none;
    }

    ul,
    ol {
        list-style: none;
    }

    :root {
        font-size: 62.5%;
    }

    .section-cave-mobile-wrapper {
        padding: 80px 0 40px;

        [data-scrollbar] {
            width: 100%;
            height: unset;
        }
        
        .scrollbar-track  {
            background: none !important;
        }
        
        .scrollbar-thumb {
            background: none !important;
        }

        .section-cave-mobile {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            width: 100%;
            // height: calc(100vh - 32rem);
            margin: 40px 0;
        }
    }

    .page-title {
        position: absolute;
        width: 100%;
        display: block;
        opacity: 1;

        .img-title {
            display: block;
            width: 90vw;
            margin: auto;
            opacity: .55;
            // width: 100%;
            // height: 130px;
        }
    }

    @media screen and (min-width: 53em) {

        .section-cave-mobile {
            height: 100vh;
            justify-content: center;
        }
    }

    .scroll-content {
        display: block;
        width: 100%;
        height: 100%;
        padding: 9rem 5vw 5rem;
        color: #fff;
    }

    .scrollarea {
        height: 100%;
    }

    .scrollarea-ctn {
        position: relative;
        // z-index: 5;
        // overflow-x: auto;
    }

    .slideshow {
        position: relative;
        width: 100%;
    }

    .slideshow__progress-ctn {
        overflow: hidden;
        position: absolute;
        bottom: 5%;
        left: calc(50% - 6.5rem);
        width: 13rem;
        height: .4rem;
        border-radius: .4rem;
        will-change: transform;
        border: 1px solid #927c60;
    }

    .slideshow__progress {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #927c60;
        border-radius: .4rem;
        transform: translateX(-100%);
    }

    .slideshow-list {
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: 0;
    }

    .slideshow-list__el {
        flex: 0 0 auto;
        width: 100%;
        min-width: 25rem;
        max-width: 40vmin;
        margin-left: 10vw;
    }

    .slideshow-list__el:last-child {
        padding-right: 10vw;
        box-sizing: content-box;
    }

    @media only screen and (min-width: 1112px) {
        .slideshow-list__el:nth-child(1) {
            transform: translateY(8vh);
        }
    }

    @media only screen and (min-width: 1112px) {
        .slideshow-list__el:nth-child(2) {
            transform: translateY(-8vh);
        }
    }

    @media only screen and (min-width: 1112px) {
        .slideshow-list__el:nth-child(3) {
            transform: translateY(8vh);
        }
    }

    @media only screen and (min-width: 1112px) {
        .slideshow-list__el:nth-child(4) {
            transform: translateY(-8vh);
        }
    }

    @media only screen and (min-width: 1112px) {
        .slideshow-list__el:nth-child(5) {
            transform: translateY(8vh);
        }
    }

    @media only screen and (min-width: 768px) {
        .slideshow-list__el {
            margin-left: 20vw;
        }
    }

    .tile {
        position: relative;
    }

    .tile__fig {
        position: relative;
        width: 100%;
    }

    .tile__fig::before {
        content: '';
        display: block;
        padding-top: 70%;
    }

    .tile__img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: opacity .3s;
    }

    .tile__img.is-loaded {
        opacity: 0;
    }
}

.image { 
    position: relative;
}
