.section-service {
    width: 100%;
    height: 200vh;
    position: relative;

    ul {
        text-align: center;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        list-style: none;

        li {
            color: #989898;
            font-weight: 300;
            line-height: 1.5em;
            letter-spacing: 1.2px;
        }
    }

    h2,
    h3 {
        color: #927c60;
    }

    h2 {
        font-size: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h3 {
        font-size: 1.2em;
    }

    @media only screen and (min-width: 1440px) {

        h2 {
            font-size: 3.5vw;
        }

        h3 {
            font-size: 1vw;
        }

        li {
            font-size: 1vw;
        }
    }

    #trigger-service {
        position: sticky;
        top: 0;
    }

    .container-desktop {
        width: 100%;
        height: 100vh;
        display: flex;

        @media only screen and (max-width: 1200px) {
            flex-direction: column;

        }

        .wrapper {
            width: 100%;
            display: flex;
            margin: auto 0;

            @media only screen and (max-width: 1200px) {
                flex-direction: column;
            }
        }

        img {
            width: 100%;
            margin: 150px auto;
        }

        .our-service {
            display: none;
            height: 90px;
            margin: 0 auto 30px;

            @media only screen and (max-width: 1200px) {
                display: block;
            }
        }

        div.our {
            width: 20%;
            height: 100vh;
            display: flex;
            justify-content: flex-end;

            @media only screen and (max-width: 1200px) {
                display: none;
            }
        }

        div.service {
            width: 20%;
            height: 100vh;
            display: flex;
            justify-content: flex-start;

            @media only screen and (max-width: 1200px) {
                display: none;
            }
        }

        div.grid {
            display: grid;
            grid-template-columns: 3fr 1fr 2fr 2fr 2fr 1fr 3fr;
            grid-template-rows: 100px 100px 100px 100px 100px 100px 100px;
            width: 60%;
            margin: auto;

            @media only screen and (max-width: 1200px) {
                margin: unset;
            }

            @media only screen and (max-width: 1280px) {
                width: 100%;
            }

            #h-1 {
                grid-column: 4;
                grid-row: 3;
                opacity: 0;
            }

            #h-2 {
                grid-column: 5;
                grid-row: 4;
                opacity: 0;
            }

            #h-3 {
                grid-column: 4;
                grid-row: 5;
                opacity: 0;
            }

            #h-4 {
                grid-column: 3;
                grid-row: 4;
                opacity: 0;
            }


            #branding {
                grid-column: 3 / 6;
                grid-row: 2 / 4;
                opacity: 0;
                display: flex;
                justify-content: center;
                align-items: center;

            }

            #communication {
                grid-column: 4 / 8;
                grid-row: 3 / 6;
                transform: rotate(90deg);
                opacity: 0;
                display: flex;
                justify-content: center;
                align-items: center;

            }

            #online {
                grid-column: 3 / 6;
                grid-row: 5 / 7;
                text-align: center;
                opacity: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                @media only screen and (min-width: 1500px) {
                    grid-row: 6;
                }
            }

            #actication {
                grid-column: 1/5;
                grid-row: 3/6;
                transform: rotate(-90deg);
                opacity: 0;
                display: flex;
                justify-content: center;
                align-items: center;

            }

            .branding {
                grid-row: 1 / 3;
                grid-column: 3 / 6;
                opacity: 0;

                @media only screen and (min-width: 1500px) {
                    grid-row: 1/2;
                }
            }

            .communication {
                text-align: left;
                grid-row: 3 / 6;
                grid-column: 6 / 8;
                opacity: 0;
            }

            .online {
                grid-row: 6 / 8;
                grid-column: 3 / 6;
                opacity: 0;

                @media only screen and (min-width: 1500px) {
                    grid-row: 7 / 9;
                }
            }

            .actication {
                text-align: right;
                grid-row: 3 / 6;
                grid-column: 1 / 3;
                opacity: 0;
            }

            .wrapper-line {
                grid-row: 4;
                grid-column: 4;
                width: 100%;
                height: 100%;
                position: relative;
                margin: auto;
                transform: rotate(45deg);

                span {
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background: #927c60;
                    transform-origin: center;
                    top: 50%;
                    left: 0%;

                    &:nth-child(1) {
                        transform: rotate(45deg) scaleX(4);
                        transform-origin: center;
                    }

                    &:nth-child(2) {
                        transform: rotate(135deg) scaleX(4);
                    }
                }
            }

        }

    }

    @media screen and (max-width: 576px) {

        .container-desktop {
            display: none;
        }

    }



    .container-mobile {
        display: none;
        padding: 100px 20px 0px;
        box-sizing: border-box;

        .wrapper {
            svg {
                width: 70%;
                height: auto;
                position: relative;
                margin: auto;
                display: block;
                padding-bottom: 40px;
            }

            h2,
            h3 {
                color: #927c60;
                font-weight: 400;
                text-align: center;
            }

            h2 {
                font-size: 20px;
                margin: 0px;
                padding-bottom: 8px;
            }

            h3 {
                white-space: pre-line;
                font-size: 32px;
                line-height: 22px;
                margin: 0 0 10px;

                @media only screen and (max-width: 576px) {
                    font-size: 7vw;
                }
            }

            ul.list-wrapper {
                li.group {
                    position: relative;
                    padding-bottom: 50px;
                }

                .wrapper {
                    padding-bottom: 20px;
                }
            }


        }
    }

    @media screen and (max-width: 576px) {
        height: unset;

        .container-mobile {
            display: block;

        }
    }

    .hidden {
        overflow: hidden;
        max-width: 60px;
        width: 100%;
    }

    .hidden-1 {
        overflow: hidden;

        .group {
            opacity: 1;
            overflow: hidden;
        }
    }
}

@media only screen and (max-width: 992px) and (orientation: landscape) {

    .section-service {
        height: unset;

        .container-desktop {
            display: none;
        }

        .container-mobile {
            display: block;

            .wrapper svg {
                width: 40%;
            }

            h3 {
                font-size: 28px !important;
            }
        }
    }
}