.navigation {

  .main-nav__canvas {
    display: none;
  }

  // @media only screen and (min-width: 1200px) {
    .main-nav__canvas {
      display: block;
      height: 100vh;
      left: 0px;
      position: fixed;
      top: 0px;
      width: 100%;
      z-index: 100;
      // z-index: 2;
    }
  // }

  #main-nav-toggle {
    position: absolute;
    visibility: hidden;

    // @media only screen and (min-width: 1200px) {
      &:checked~nav.main-nav {
        display: flex;
        opacity: 1;
        pointer-events: all;
        z-index: 4;
      }
    // }

    // @media only screen and (max-width: 1200px) {
      &:checked~nav.main-nav-mobile {
        display: flex;
        opacity: 1;
        pointer-events: all;
      }
    // }
  }

  .box-btn {
    height: 45px;
    width: 45px;
    position: fixed;
    z-index: 5;
    margin: 20px;
    top: 0;

    .group-btn {
      z-index: 5;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    nav.main-nav {
      transition: 0.3s opacity;
      align-items: center;
      background: #151515;
      justify-content: center;
      display: flex;
      height: 100%;
      left: 0px;
      opacity: 0;
      pointer-events: none;
      position: fixed;
      top: 0px;
      width: 100%;
      z-index: 1;
    }

    // @media only screen and (max-width: 1200px) {
      nav.main-nav {
        display: none;
      }
    // }
  }

  .wrapper-header {
    // width: 100%;
    // height: auto;
    // display: flex;
    // position: fixed;
    // justify-content: space-between;
    // padding: 25px 20px;
    // align-items: center;
    // z-index: 5;

    @media only screen and (max-width: 576px) {
      padding: 15px;
    }

    // .box-btn {
    //   height: 45px;
    //   width: 45px;
    //   position: relative;

    //   .group-btn {
    //     z-index: 3;
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //   }

    //   nav.main-nav {
    //     transition: 0.3s opacity;
    //     align-items: center;
    //     background: #151515;
    //     justify-content: center;
    //     display: flex;
    //     height: 100%;
    //     left: 0px;
    //     opacity: 0;
    //     pointer-events: none;
    //     position: fixed;
    //     top: 0px;
    //     width: 100%;
    //     z-index: 1;
    //   }
    //   @media only screen and (max-width: 1200px) {
    //     nav.main-nav {
    //       display: none;
    //     }
    //   }
    // }

    .box-logo {
      max-width: 150px;

      @media only screen and (max-width: 992px) {
        max-width: 130px;
      }

      @media only screen and (max-width: 576px) {
        max-width: 100px;
      }

      img {
        width: 100%;
        transition: all 0.6s ease-in-out;
        // transition-delay: 1s;
      }
    }
  }

}

// @media only screen and (min-width: 1200px) {
  nav.main-nav-mobile {
    display: none;
  }
// }

// @media only screen and (max-width: 1200px) {

  nav.main-nav-mobile {
    align-items: center;
    // background: #151515;
    background: #efefef;
    justify-content: center;
    width: 100%;
    // width: 80%;
    opacity: 0;
    position: fixed;
    z-index: 4;
    pointer-events: none;
    top: 0;
    text-align: center;
    display: table;
    transition: opacity 1.2s ease-out;

    &.active {
      opacity: 1;
      pointer-events: all;
      transition: opacity .5s ease-out;

      // z-index: 4;
    }

    .wrapper {
      width: 100%;
      height: 100vh;
      z-index: 9;
      // display: table-cell;
      vertical-align: middle;
      position: relative;
      z-index: 1;
      background: #151515;
      opacity: 0;
      transition: opacity .3s ease-out;

      @media only screen and (max-width: 576px) {
        vertical-align: top;
      }

      .wrapper-data {
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;

        // @media only screen and (max-width: 576px) {
          height: calc(100vh - 170px);
          -webkit-mask-image: linear-gradient(180deg, transparent 0, transparent 1%, #000 20%, #000 50%, #000 80%, transparent);
        // }

        div.container-link {

          // @media only screen and (max-width: 576px) {
            padding: 100px 48px 40px;
          // }

          div.box-link {
            text-align: center;
            letter-spacing: .2rem;

            a.item-link {
              color: #989898;
              margin: 50px 0;
              display: flex;
              flex-direction: column;
              transition: opacity .5s ease;

              .title {
                font-size: 32px;
                font-weight: 100;
                margin-bottom: 5px;

              }

              .title-number {
                font-size: 14px;
                font-weight: 100;
                opacity: .7;
              }
            }
          }
        }
      }
    }
  }
// }

@media only screen and (max-width: 992px) and (orientation: landscape) {
  nav.main-nav-mobile {
    .wrapper-data {
      height: 100vh;
      overflow-y: scroll;

      .container-link {
        padding: 100px 48px 40px;
      }
    }
  }
}