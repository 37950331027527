.section-work {
    width: 100%;
    height: 100%;
    // height: 100vh;
    position: relative;
    padding: 40px 0;
    box-sizing: border-box;

    @media only screen and (max-width: 576px) {
        padding: 60px 0;
    }

    .container {
        width: 100%;
        svg {
            width: 60%;
            // height: 90px;
            margin: auto auto 50px;
            display: block;

            @media only screen and (max-width: 1024px) {
                margin: auto auto 0px;
            }

            @media only screen and (max-width: 576px) {
                width: 80%;
            }
        }
    }
}