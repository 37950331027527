section.section-whatwedo {
    width: 100%;
    height: 100vh;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    h1 {
        font-size: 3em;
        letter-spacing: 4px;
        margin: 0;
    }

    hr {
        margin: 50px auto;
        width: 30%;
        border: 1px solid #808080;
    }

    p {
        color: #989898;
        font-weight: 300;
        letter-spacing: 1.2px;
        line-height: 1.5em;
        font-size: 14px;
        margin: 0px 0 25px;
        white-space: pre-line;
    }

    @media only screen and (max-width: 576px) {
        padding: 60px 20px;
        // height: unset;

        h1 {
            font-size: 2.2em;
        }

        ul div:nth-child(1) p {
            white-space: normal !important;
        }
    }


    .container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .box-left {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            padding-right: 60px;
            z-index: 1;

            svg {
                max-width: 400px;
                width: 100%;

                @media only screen and (max-width: 576px) {
                    max-width: 250px;
                    width: 100%;
                }
            }

        }

        span.space {
            position: absolute;
            // transform: rotate(90deg) scaleX(0);
            width: 100%;
            height: 1px;
            background: #927c60;
            top: 50%;
            // left: 50%;
            // transform:translateX(-50%) rotate(45deg) scaleX(0.5);
            transform: scaleX(0);
            transform-origin: center;
        }

        .box-right {
            width: 50%;
            padding-left: 20px;

            span:nth-child(1) {
                color: #808080;
            }

            span:nth-child(2) {
                color: #927c60;
            }

            p {
                margin-left: 110px;
            }

            div:nth-child(1) {
                margin-left: -120px;

                p {
                    margin-left: 120px;
                }
            }

            div:nth-child(2) {
                margin-left: 0px;

                p {
                    margin-left: 101px;
                }

                @media only screen and (min-width: 577px) {
                    p {
                        white-space: normal !important;
                    }
                }
            }

            div:nth-child(3) {
                margin-left: 100px;
            }

            @media only screen and (max-width: 1024px) {
                div.hidden {
                    margin-left: 0;
                }
            }

        }

        @media only screen and (max-width: 1024px) {
            flex-direction: column;

            .box-left,
            .box-right {
                width: 100%;
                padding: 0;
            }

            span.space {
                display: none;
            }

            .box-left {
                justify-content: center;
                margin: 50px 0;
            }

            .box-right {
                text-align: center;

                p,
                li {
                    margin: 0 !important;
                }

                li {
                    padding: 0px 0 40px;
                }
            }
        }

        @media only screen and (max-width: 576px) {
            .box-left {
                margin: 0;
            }
        }
    }

    .hidden {
        // margin-bottom: 7.5vw;
        // margin-bottom: calc(30*100vw/var(--size));
        overflow: hidden;

        li {
            overflow: hidden;
            opacity: 1;

        }

    }
}

@media only screen and (max-width: 992px) and (orientation: landscape) {
    section.section-whatwedo {
        height: unset;

        h1 {
            font-size: 2em;
        }

        .container {
            .box-left {
                margin: 20px 0;

                svg {
                    max-width: 300px;
                    width: 100%;
                }
            }
        }
    }
}