.section-exceptionalwork {
    width: 100%;
    height: 100%;
    z-index: 3;
    position: relative;
    // overflow: auto;

    .portfolios {
        display: flex;
        flex-wrap: wrap;
    }

    .hide {
        display: none;
    }

    h1 {
        color: #808080;
        font-size: 2.5em;

        @media only screen and (max-width: 576px) {
            font-size: 2em;
        }
    }

    .box-1 {
        height: 130px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: fixed;
        z-index: 1;
        top: 0;
        background: #000;
    }

    .box-1-mobile {
        display: none;
    }

    @media only screen and (max-width: 992px) {
        .box-1 {
            display: none;
        }

        .box-1-mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 210px;

            .container-text-front {
                width: 100%;
                height: 150px;
                background-color: #000;
                position: relative;
                z-index: 3;
                display: flex;
                justify-content: center;
                align-items: center;

                h1 {
                    margin: 0;
                    font-size: 2.5em;
                    opacity: 1;
                    transition: opacity .3s ease-in-out;

                    @media only screen and (max-width: 576px){
                        margin-top: 50px;
                    }
                }

                &.gone {
                    h1 {
                        opacity: 0;
                    }
                }
            }

            .container-text-back {
                width: 100%;
                height: 100px;
                background-color: #000;
                position: fixed;
                top: 0;
                z-index: 2;
                display: flex;
                justify-content: center;
                align-items: center;
                transition:1s all ease;

                h1 {
                    font-size: 2em;
                    margin: 0;
                    opacity: 0;
                }

                &.display {
                    h1 {
                        opacity: 1;
                        transition: all .5s ease-out;
                    }
                    // z-index: 2;
                }
            }

            .container-mobile {
                
                height: 60px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #000;
                overflow: hidden;
                &.display {
                    position: fixed;
                    top: 100px;
                    z-index: 2;
                    // transition:1s all ease;
                }
            }
        }
    }

    .box-2 {
        .box-left {
            background: #000;
            width: 20%;
            height: calc(100% - 130px);
            overflow: hidden;
            padding: 20px;
            box-sizing: border-box;
            position: fixed;
            bottom: 0;

            .container {
                text-align: left;

                h1 {
                    bottom: 0;
                    position: absolute;
                    color: #927C60;
                }

                div.text-header {
                    display: flex;
                    flex-direction: column;

                    h4 {
                        margin: 0;
                        padding-left: 20px;
                    }

                    img {
                        max-width: 13px;
                    }
                }

                p {
                    color: #989898;
                }

            }

        }

        .box-right {
            width: 80%;
            position: relative;
            margin-left: auto;
            margin-top: 130px;
           
        }

        @media only screen and (max-width: 992px) {
            .box-left {
                display: none;
            }

            .box-right {
                width: 100%;
                margin-top: unset;
            }
        }
    }

    h1 {
        text-align: left;

    }

    h4 {
        color: #927c60;
        font-size: 1.3em;
        font-weight: 100;
    }

    p {
        text-align: justify;
    }

    ul {
        list-style: none;
        padding: 0;
        padding-left: 10px;

        p {
            font-weight: 500;
            color: #3a3a3a;
            margin: 0;
            letter-spacing: 1px;
        }

    }

    ul.categories {
        padding-left: 15px;
        margin: 0;
        height: 100%;
        align-items: center;

        li {

            a {
                font-weight: 500;
                color: #3a3a3a;
                margin: 0;
                letter-spacing: 1px;

                &.active {
                    color: #989898;
                }
    
                &:hover {
                    color: #989898;
                }
            }

        }


        @media only screen and (max-width: 992px) {
            padding-left: 0;
            display: flex;
            overflow-x: scroll;

            li {
                padding: 0 10px;

                a {
                    white-space: nowrap;
                }
            }

        }

        @media only screen and (max-width: 576px) {
            padding-left: 20px;
            padding-right: 20px;
            -webkit-mask-image: linear-gradient(90deg, transparent 0, transparent 1%, #000 20%, #000 50%, #000 90%, transparent);

            li {
                &:first-child {
                    padding-left: 30px;
                }

                &:last-child {
                    padding-right: 30px;
                }

            }
        }

    }

    @keyframes fadeIn {
        from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
    }

    .hidden {
        overflow: hidden;

        span {
            opacity: 1;
        }
    
        h1 {
            opacity: 1;
        }
    }
}