@import url(https://use.typekit.net/qbg7hup.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  font-family: aw-conqueror-didot, serif;
  font-weight: 700;
  font-style: normal;
  cursor: none !important;
}

body {
  background: transparent;
  /* background: #cfcfcf; */
  width: 100%;
  -webkit-font-smoothing: antialiased;
  cursor: none !important;
  /* height: 100vh; */
  /* overflow-x: hidden; */
  
}

html {
  cursor: none !important;
  background: #000;
}


/* .front-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .front-page {
    position: unset;
    top: unset;
    left: unset;
    width: unset;
  }
} */

.fake-scroll {
  position: absolute;
  top: 0;
  /* width: 1px; */
  width: 10px;
  /* background: red; */
  z-index: 2;
}

/* font-family: gill-sans-nova, sans-serif; */
/* font-family: aw-conqueror-didot, serif; */

li, a, p {
  font-family: gill-sans-nova, sans-serif;
}

li, a, p {
  font-size: 16px;
}

@media only screen and (max-width: 576px) {
  li, a, p {
    font-size: 14px;
  }
}

html {
  overflow-x: hidden;
  /* overflow: scroll;
  overflow-x: hidden; */
}
::-webkit-scrollbar {
    /* width: 0px;
    height: 0px; */
    /* display: none; */
    /* background: transparent;  */
}

::-webkit-scrollbar {
  width: 8px;
  background:rgba(152,152,152,0.3);
}

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(152,152,152,1);  */
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
  background:#927c60;
} 

@media only screen and (max-width: 992px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

/* .front-page {
  height: 100%;
  position: relative;
  overflow: hidden;
  background: #000;
  z-index: 1;
} */

.transparent-space {
  height: 70vh;
  width: 100%;
  pointer-events: none;
  z-index: 1;
  position: relative;
}

.transparent-space img {
  width: 30%;
  display: block;
  margin: auto;
  padding-top: 7vh;
}

@media only screen and (max-width: 576px) {
  .transparent-space img {
    width: 60%;
  }
}

.section-map {
  width: 100%;
  height: 70vh;
  position: fixed;
  bottom: 0;
  /* z-index: -1; */
}

.bodyOver {
  /* background: blue; */
  overflow: hidden !important;
  /* position: relative; */
  position: fixed;
  height: 100%;
}

.htmlOver {
  /* border: 10px solid red; */
  overflow: hidden !important;
}

.rootOver {
  overflow: hidden;
}

video:hover {
  cursor: none !important;
}

/* .App video {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-position: center center;
  background-size: contain;
  object-fit: cover;
  z-index: -1;
  outline: none;
} */
.section-homeanim {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  padding: 40px;
  box-sizing: border-box; }
  @media only screen and (max-width: 992px) {
    .section-homeanim {
      height: calc(1vh * 100);
      height: calc(var(--vh, 1vh) * 100); } }
  @media only screen and (max-width: 576px) {
    .section-homeanim {
      padding: 20px; } }
  .section-homeanim p {
    white-space: pre;
    font-size: 18px;
    text-align: center;
    color: #fff;
    font-weight: 300;
    letter-spacing: 1.2px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 1;
    margin: 0;
    transition: -webkit-filter 1.2s ease;
    transition: filter 1.2s ease;
    transition: filter 1.2s ease, -webkit-filter 1.2s ease; }
    @media only screen and (max-width: 576px) {
      .section-homeanim p {
        font-size: 12px;
        white-space: nowrap; } }
  .section-homeanim .box {
    width: 100%;
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .section-homeanim .box svg {
      z-index: 1;
      width: 40%;
      fill: #fff;
      -webkit-filter: blur(8px);
              filter: blur(8px);
      opacity: 0;
      transition: -webkit-filter 1.2s ease;
      transition: filter 1.2s ease;
      transition: filter 1.2s ease, -webkit-filter 1.2s ease; }
  @media only screen and (max-width: 576px) {
    .section-homeanim {
      padding: 20px; }
      .section-homeanim .box {
        width: 100%;
        padding: 0; }
        .section-homeanim .box svg {
          width: 70%; } }
  .section-homeanim .container {
    z-index: 2;
    position: relative;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end; }
    .section-homeanim .container h1 {
      color: #927C60;
      font-size: 1.5em;
      opacity: 0;
      text-align: center;
      z-index: 1;
      margin: 0; }
      .section-homeanim .container h1 > span {
        display: inline-block; }
  .section-homeanim .split {
    text-align: center; }
    .section-homeanim .split .characters {
      display: inline-block; }
    .section-homeanim .split .separate {
      position: absolute; }
  .section-homeanim video#video-cover {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    background-position: center center;
    background-size: contain;
    object-fit: cover;
    z-index: 1;
    opacity: 1;
    outline: none; }

@media only screen and (max-width: 992px) and (orientation: landscape) {
  .section-homeanim {
    padding: 20px; }
    .section-homeanim p {
      font-size: 15px !important; }
    .section-homeanim h1 {
      font-size: 1em !important; } }

.section-work {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 40px 0;
  box-sizing: border-box; }
  @media only screen and (max-width: 576px) {
    .section-work {
      padding: 60px 0; } }
  .section-work .container {
    width: 100%; }
    .section-work .container svg {
      width: 60%;
      margin: auto auto 50px;
      display: block; }
      @media only screen and (max-width: 1024px) {
        .section-work .container svg {
          margin: auto auto 0px; } }
      @media only screen and (max-width: 576px) {
        .section-work .container svg {
          width: 80%; } }

.portal {
  background: #000;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 40px;
  box-sizing: border-box;
  z-index: 5;
  transition: opacity .5s ease-out;
  pointer-events: none; }
  @media only screen and (max-width: 768px) {
    .portal {
      height: 100vh; } }
  .portal.active {
    opacity: 1;
    pointer-events: all; }
  .portal p {
    margin: 0;
    font-size: 13px;
    color: #989898;
    font-weight: 400; }
  .portal h1 {
    color: #927C60 !important;
    margin: 0;
    margin-bottom: 10px; }
  .portal .text-title {
    font-size: 3.5em; }
  .portal .text-company {
    font-size: 2.5em; }
  .portal p.text-description {
    text-align: justify;
    font-size: 14px; }
  .portal .container-wrapper {
    width: 100%;
    height: 100%;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    padding: 40px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .portal .container-wrapper.fade {
      transition: opacity .3s ease;
      opacity: 0; }
    @media only screen and (max-width: 576px) {
      .portal .container-wrapper {
        padding: 20px 20px 80px; } }
    @media only screen and (max-width: 992px) and (orientation: landscape) {
      .portal .container-wrapper {
        overflow-y: scroll; } }
  .portal .wrapper-1 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 10px;
    position: relative; }
    @media only screen and (max-width: 576px) {
      .portal .wrapper-1 {
        width: 80%; } }
    .portal .wrapper-1 .container-text h1 {
      margin: 0; }
    .portal .wrapper-1 .container-text p {
      text-transform: uppercase; }
  .portal .wrapper-container {
    width: 90%;
    margin: auto;
    position: relative; }
    @media only screen and (max-width: 1024px) {
      .portal .wrapper-container {
        width: 100%; } }
    .portal .wrapper-container .wrapper-portal {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      height: 100%;
      position: relative; }
      .portal .wrapper-container .wrapper-portal .container-left {
        width: 90%;
        margin: auto;
        padding: 20px;
        position: relative; }
        @media only screen and (max-width: 1200px) {
          .portal .wrapper-container .wrapper-portal .container-left {
            width: 100%; } }
        @media only screen and (max-width: 1024px) and (orientation: portrait) {
          .portal .wrapper-container .wrapper-portal .container-left {
            padding: 0; } }
      .portal .wrapper-container .wrapper-portal .container-right {
        width: 40%;
        padding: 0 40px; }
        @media only screen and (max-width: 1024px) {
          .portal .wrapper-container .wrapper-portal .container-right {
            padding: 40px;
            width: 100%; } }
        @media only screen and (max-width: 576px) {
          .portal .wrapper-container .wrapper-portal .container-right {
            padding: 40px 0; } }
  .portal .wrapper-btn {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .portal .wrapper-btn .count {
      display: -webkit-flex;
      display: flex;
      margin: 0 25px;
      -webkit-align-items: center;
              align-items: center; }
      .portal .wrapper-btn .count span {
        width: 5px;
        height: 1px;
        background-color: #989898;
        margin: 0 10px; }
    .portal .wrapper-btn div.btn {
      content: '';
      background-position: center;
      background-repeat: no-repeat;
      height: 20px;
      width: 20px;
      display: block; }
      .portal .wrapper-btn div.btn.prev {
        background-image: url(/assets/ic-left.svg); }
      .portal .wrapper-btn div.btn.next {
        background-image: url(/assets/ic-right.svg); }
  .portal button.closePortal {
    border: none;
    outline: none;
    position: fixed;
    right: 40px;
    width: 28px;
    height: 28px;
    background: url(/assets/plus.svg) no-repeat center;
    background-color: transparent;
    z-index: 5; }
    @media only screen and (max-width: 576px) {
      .portal button.closePortal {
        right: 20px; } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }
  .portal .hidden {
    overflow: hidden; }
    .portal .hidden h1 {
      opacity: 1; }

.container-carousel {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .container-carousel .slick-next,
  .container-carousel .slick-prev {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 1;
    opacity: .5;
    transition: opacity .5s ease-out; }
    .container-carousel .slick-next:hover,
    .container-carousel .slick-prev:hover {
      opacity: 1; }
    .container-carousel .slick-next:before,
    .container-carousel .slick-prev:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      top: 50%;
      left: 50%; }
  .container-carousel .slick-next {
    right: 0; }
    .container-carousel .slick-next:before {
      content: '';
      background: url(/assets/ic-right.svg) center no-repeat; }
  .container-carousel .slick-prev {
    left: 0; }
    .container-carousel .slick-prev:before {
      content: '';
      background: url(/assets/ic-left.svg) center no-repeat; }
  .container-carousel .img-carousel {
    padding-bottom: 40%;
    height: 0;
    -webkit-justify-content: center;
            justify-content: center;
    margin: auto;
    position: relative;
    outline: none; }
    @media screen and (max-width: 576px) {
      .container-carousel .img-carousel {
        padding-bottom: 80%; } }
  .container-carousel .img-carousel-preview {
    padding-bottom: 43%;
    -webkit-justify-content: center;
            justify-content: center;
    margin: auto;
    position: relative;
    outline: none; }
    .container-carousel .img-carousel-preview.video img {
      opacity: 0.3; }
    .container-carousel .img-carousel-preview.video:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 15px solid #fff; }
      @media screen and (max-width: 768px) {
        .container-carousel .img-carousel-preview.video:after {
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 10px solid #fff; } }
  .container-carousel .count-element {
    display: block; }

.slick-slider:nth-child(1) {
  margin-bottom: 10px; }
  .slick-slider:nth-child(1) .slick-slide div img {
    width: 100%;
    position: absolute;
    object-fit: contain;
    height: 100%;
    display: block; }
  .slick-slider:nth-child(1) .slick-slide div video {
    z-index: 9;
    outline: none;
    width: 100%;
    position: absolute;
    object-fit: contain;
    height: 100%;
    display: block; }

.slick-slider:nth-child(2) .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-slider:nth-child(2) .slick-track .slick-slide {
    transition: opacity .5s ease-out;
    opacity: .3; }
    .slick-slider:nth-child(2) .slick-track .slick-slide:hover {
      opacity: 1; }
    .slick-slider:nth-child(2) .slick-track .slick-slide.slick-active.slick-current {
      opacity: 1; }

.slick-slider:nth-child(2) .slick-slide {
  padding: 0 10px; }
  .slick-slider:nth-child(2) .slick-slide div img {
    width: 100%;
    position: absolute;
    object-fit: cover;
    height: 100%;
    display: block; }
  .slick-slider:nth-child(2) .slick-slide div video {
    z-index: 9;
    outline: none;
    width: 100%;
    position: absolute;
    object-fit: cover;
    height: 100%;
    display: block; }

.slick-slide.slick-active.slick-current {
  z-index: 9999 !important; }

.grid-work {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: calc(70vw / 4);
  width: 100%; }
  @media only screen and (max-width: 1200px) {
    .grid-work {
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: calc(100vw / 4);
      height: 100%; } }
  @media only screen and (max-width: 576px) {
    .grid-work {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: calc(100vh / 4);
      height: 100%; } }
  .grid-work .thumbnail {
    width: 100%;
    height: 100%;
    position: relative;
    background: #000;
    transition: all .6s ease-out;
    opacity: 0;
    -webkit-animation: fadeIn .6s forwards .2s;
            animation: fadeIn .6s forwards .2s; }
    .grid-work .thumbnail img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      transition: all .5s ease-out; }
      .grid-work .thumbnail img:hover {
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);
        opacity: 0; }
    .grid-work .thumbnail button {
      width: 100%;
      height: 100%;
      background-color: #000;
      border: none;
      outline: none; }
      .grid-work .thumbnail button a {
        font-size: 4vw;
        text-decoration: none;
        color: #927c60;
        font-family: aw-conqueror-didot, serif;
        white-space: pre-line; }
        @media only screen and (max-width: 1200px) {
          .grid-work .thumbnail button a {
            font-size: 5vw; } }
        @media only screen and (max-width: 576px) {
          .grid-work .thumbnail button a {
            font-size: 10vw; } }

.section-service {
  width: 100%;
  height: 200vh;
  position: relative; }
  .section-service ul {
    text-align: center;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    list-style: none; }
    .section-service ul li {
      color: #989898;
      font-weight: 300;
      line-height: 1.5em;
      letter-spacing: 1.2px; }
  .section-service h2,
  .section-service h3 {
    color: #927c60; }
  .section-service h2 {
    font-size: 3em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
  .section-service h3 {
    font-size: 1.2em; }
  @media only screen and (min-width: 1440px) {
    .section-service h2 {
      font-size: 3.5vw; }
    .section-service h3 {
      font-size: 1vw; }
    .section-service li {
      font-size: 1vw; } }
  .section-service #trigger-service {
    position: -webkit-sticky;
    position: sticky;
    top: 0; }
  .section-service .container-desktop {
    width: 100%;
    height: 100vh;
    display: -webkit-flex;
    display: flex; }
    @media only screen and (max-width: 1200px) {
      .section-service .container-desktop {
        -webkit-flex-direction: column;
                flex-direction: column; } }
    .section-service .container-desktop .wrapper {
      width: 100%;
      display: -webkit-flex;
      display: flex;
      margin: auto 0; }
      @media only screen and (max-width: 1200px) {
        .section-service .container-desktop .wrapper {
          -webkit-flex-direction: column;
                  flex-direction: column; } }
    .section-service .container-desktop img {
      width: 100%;
      margin: 150px auto; }
    .section-service .container-desktop .our-service {
      display: none;
      height: 90px;
      margin: 0 auto 30px; }
      @media only screen and (max-width: 1200px) {
        .section-service .container-desktop .our-service {
          display: block; } }
    .section-service .container-desktop div.our {
      width: 20%;
      height: 100vh;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
      @media only screen and (max-width: 1200px) {
        .section-service .container-desktop div.our {
          display: none; } }
    .section-service .container-desktop div.service {
      width: 20%;
      height: 100vh;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-start;
              justify-content: flex-start; }
      @media only screen and (max-width: 1200px) {
        .section-service .container-desktop div.service {
          display: none; } }
    .section-service .container-desktop div.grid {
      display: grid;
      grid-template-columns: 3fr 1fr 2fr 2fr 2fr 1fr 3fr;
      grid-template-rows: 100px 100px 100px 100px 100px 100px 100px;
      width: 60%;
      margin: auto; }
      @media only screen and (max-width: 1200px) {
        .section-service .container-desktop div.grid {
          margin: unset; } }
      @media only screen and (max-width: 1280px) {
        .section-service .container-desktop div.grid {
          width: 100%; } }
      .section-service .container-desktop div.grid #h-1 {
        grid-column: 4;
        grid-row: 3;
        opacity: 0; }
      .section-service .container-desktop div.grid #h-2 {
        grid-column: 5;
        grid-row: 4;
        opacity: 0; }
      .section-service .container-desktop div.grid #h-3 {
        grid-column: 4;
        grid-row: 5;
        opacity: 0; }
      .section-service .container-desktop div.grid #h-4 {
        grid-column: 3;
        grid-row: 4;
        opacity: 0; }
      .section-service .container-desktop div.grid #branding {
        grid-column: 3 / 6;
        grid-row: 2 / 4;
        opacity: 0;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center; }
      .section-service .container-desktop div.grid #communication {
        grid-column: 4 / 8;
        grid-row: 3 / 6;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        opacity: 0;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center; }
      .section-service .container-desktop div.grid #online {
        grid-column: 3 / 6;
        grid-row: 5 / 7;
        text-align: center;
        opacity: 0;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center; }
        @media only screen and (min-width: 1500px) {
          .section-service .container-desktop div.grid #online {
            grid-row: 6; } }
      .section-service .container-desktop div.grid #actication {
        grid-column: 1/5;
        grid-row: 3/6;
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
        opacity: 0;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center; }
      .section-service .container-desktop div.grid .branding {
        grid-row: 1 / 3;
        grid-column: 3 / 6;
        opacity: 0; }
        @media only screen and (min-width: 1500px) {
          .section-service .container-desktop div.grid .branding {
            grid-row: 1/2; } }
      .section-service .container-desktop div.grid .communication {
        text-align: left;
        grid-row: 3 / 6;
        grid-column: 6 / 8;
        opacity: 0; }
      .section-service .container-desktop div.grid .online {
        grid-row: 6 / 8;
        grid-column: 3 / 6;
        opacity: 0; }
        @media only screen and (min-width: 1500px) {
          .section-service .container-desktop div.grid .online {
            grid-row: 7 / 9; } }
      .section-service .container-desktop div.grid .actication {
        text-align: right;
        grid-row: 3 / 6;
        grid-column: 1 / 3;
        opacity: 0; }
      .section-service .container-desktop div.grid .wrapper-line {
        grid-row: 4;
        grid-column: 4;
        width: 100%;
        height: 100%;
        position: relative;
        margin: auto;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
        .section-service .container-desktop div.grid .wrapper-line span {
          position: absolute;
          width: 100%;
          height: 1px;
          background: #927c60;
          -webkit-transform-origin: center;
                  transform-origin: center;
          top: 50%;
          left: 0%; }
          .section-service .container-desktop div.grid .wrapper-line span:nth-child(1) {
            -webkit-transform: rotate(45deg) scaleX(4);
                    transform: rotate(45deg) scaleX(4);
            -webkit-transform-origin: center;
                    transform-origin: center; }
          .section-service .container-desktop div.grid .wrapper-line span:nth-child(2) {
            -webkit-transform: rotate(135deg) scaleX(4);
                    transform: rotate(135deg) scaleX(4); }
  @media screen and (max-width: 576px) {
    .section-service .container-desktop {
      display: none; } }
  .section-service .container-mobile {
    display: none;
    padding: 100px 20px 0px;
    box-sizing: border-box; }
    .section-service .container-mobile .wrapper svg {
      width: 70%;
      height: auto;
      position: relative;
      margin: auto;
      display: block;
      padding-bottom: 40px; }
    .section-service .container-mobile .wrapper h2,
    .section-service .container-mobile .wrapper h3 {
      color: #927c60;
      font-weight: 400;
      text-align: center; }
    .section-service .container-mobile .wrapper h2 {
      font-size: 20px;
      margin: 0px;
      padding-bottom: 8px; }
    .section-service .container-mobile .wrapper h3 {
      white-space: pre-line;
      font-size: 32px;
      line-height: 22px;
      margin: 0 0 10px; }
      @media only screen and (max-width: 576px) {
        .section-service .container-mobile .wrapper h3 {
          font-size: 7vw; } }
    .section-service .container-mobile .wrapper ul.list-wrapper li.group {
      position: relative;
      padding-bottom: 50px; }
    .section-service .container-mobile .wrapper ul.list-wrapper .wrapper {
      padding-bottom: 20px; }
  @media screen and (max-width: 576px) {
    .section-service {
      height: unset; }
      .section-service .container-mobile {
        display: block; } }
  .section-service .hidden {
    overflow: hidden;
    max-width: 60px;
    width: 100%; }
  .section-service .hidden-1 {
    overflow: hidden; }
    .section-service .hidden-1 .group {
      opacity: 1;
      overflow: hidden; }

@media only screen and (max-width: 992px) and (orientation: landscape) {
  .section-service {
    height: unset; }
    .section-service .container-desktop {
      display: none; }
    .section-service .container-mobile {
      display: block; }
      .section-service .container-mobile .wrapper svg {
        width: 40%; }
      .section-service .container-mobile h3 {
        font-size: 28px !important; } }

.section-cave {
  width: 100%;
  height: 100%;
  position: relative; }
  .section-cave .container-desktop {
    padding: 80px 0; }
    .section-cave .container-desktop .grid-7 {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      width: 100%;
      max-width: 1500px;
      position: relative;
      margin: auto; }
      .section-cave .container-desktop .grid-7 img {
        width: 100%; }
      .section-cave .container-desktop .grid-7 span {
        border: #927c60 1px solid; }
      .section-cave .container-desktop .grid-7 .sq-1 {
        margin-left: 15%;
        margin-bottom: 30%;
        margin-right: 30%;
        margin-top: -10%;
        grid-row: 1;
        grid-column: 2/6; }
      .section-cave .container-desktop .grid-7 .sq-2 {
        margin-bottom: 60%;
        margin-top: 50%;
        margin-left: 10%;
        grid-row: 1/5;
        grid-column: 10/13; }
      .section-cave .container-desktop .grid-7 .sq-3 {
        grid-row: 5 / 6;
        grid-column: 9 / 13;
        margin-bottom: 20%;
        margin-top: -20%;
        margin-left: 10%; }
      .section-cave .container-desktop .grid-7 .img-office {
        grid-row: 4;
        grid-column: 2;
        margin-top: auto;
        max-width: 45px; }
      .section-cave .container-desktop .grid-7 .img-cave {
        grid-row: 1/5;
        grid-column: 2 / 7;
        margin: auto;
        padding-right: 10px; }
      .section-cave .container-desktop .grid-7 .img-c1 {
        grid-row: 1;
        grid-column: 4/7;
        padding-right: 10px;
        margin-top: 60px; }
        @media only screen and (max-width: 1024px) {
          .section-cave .container-desktop .grid-7 .img-c1 {
            margin-top: 40px; } }
      .section-cave .container-desktop .grid-7 .img-2 {
        grid-row: 1 /2;
        grid-column: 7/10;
        padding-left: 10px;
        margin-bottom: 10px; }
      .section-cave .container-desktop .grid-7 .img-3 {
        grid-row: 4;
        grid-column: 3/7;
        margin-top: auto;
        padding-right: 10px; }
      .section-cave .container-desktop .grid-7 .img-4 {
        grid-row: 4;
        grid-column: 7/12;
        padding-left: 10px;
        margin-top: 10px; }
      .section-cave .container-desktop .grid-7 .img-5 {
        grid-row: 5;
        grid-column: 5 / 9;
        margin-top: 20px; }

.section-cave-mobile, .page-title {
  display: none; }

@media only screen and (max-width: 576px) {
  .section-cave {
    display: none; }
  ul,
  ol {
    list-style: none; }
  :root {
    font-size: 62.5%; }
  .section-cave-mobile-wrapper {
    padding: 80px 0 40px; }
    .section-cave-mobile-wrapper [data-scrollbar] {
      width: 100%;
      height: unset; }
    .section-cave-mobile-wrapper .scrollbar-track {
      background: none !important; }
    .section-cave-mobile-wrapper .scrollbar-thumb {
      background: none !important; }
    .section-cave-mobile-wrapper .section-cave-mobile {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
      -webkit-align-items: center;
              align-items: center;
      position: relative;
      width: 100%;
      margin: 40px 0; }
  .page-title {
    position: absolute;
    width: 100%;
    display: block;
    opacity: 1; }
    .page-title .img-title {
      display: block;
      width: 90vw;
      margin: auto;
      opacity: .55; } }

@media only screen and (max-width: 576px) and (min-width: 53em) {
  .section-cave-mobile {
    height: 100vh;
    -webkit-justify-content: center;
            justify-content: center; } }

@media only screen and (max-width: 576px) {
  .scroll-content {
    display: block;
    width: 100%;
    height: 100%;
    padding: 9rem 5vw 5rem;
    color: #fff; }
  .scrollarea {
    height: 100%; }
  .scrollarea-ctn {
    position: relative; }
  .slideshow {
    position: relative;
    width: 100%; }
  .slideshow__progress-ctn {
    overflow: hidden;
    position: absolute;
    bottom: 5%;
    left: calc(50% - 6.5rem);
    width: 13rem;
    height: .4rem;
    border-radius: .4rem;
    will-change: transform;
    border: 1px solid #927c60; }
  .slideshow__progress {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #927c60;
    border-radius: .4rem;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  .slideshow-list {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
    padding-left: 0; }
  .slideshow-list__el {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
    min-width: 25rem;
    max-width: 40vmin;
    margin-left: 10vw; }
  .slideshow-list__el:last-child {
    padding-right: 10vw;
    box-sizing: content-box; } }

@media only screen and (max-width: 576px) and (min-width: 1112px) {
  .slideshow-list__el:nth-child(1) {
    -webkit-transform: translateY(8vh);
            transform: translateY(8vh); } }

@media only screen and (max-width: 576px) and (min-width: 1112px) {
  .slideshow-list__el:nth-child(2) {
    -webkit-transform: translateY(-8vh);
            transform: translateY(-8vh); } }

@media only screen and (max-width: 576px) and (min-width: 1112px) {
  .slideshow-list__el:nth-child(3) {
    -webkit-transform: translateY(8vh);
            transform: translateY(8vh); } }

@media only screen and (max-width: 576px) and (min-width: 1112px) {
  .slideshow-list__el:nth-child(4) {
    -webkit-transform: translateY(-8vh);
            transform: translateY(-8vh); } }

@media only screen and (max-width: 576px) and (min-width: 1112px) {
  .slideshow-list__el:nth-child(5) {
    -webkit-transform: translateY(8vh);
            transform: translateY(8vh); } }

@media only screen and (max-width: 576px) and (min-width: 768px) {
  .slideshow-list__el {
    margin-left: 20vw; } }

@media only screen and (max-width: 576px) {
  .tile {
    position: relative; }
  .tile__fig {
    position: relative;
    width: 100%; }
  .tile__fig::before {
    content: '';
    display: block;
    padding-top: 70%; }
  .tile__img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: opacity .3s; }
  .tile__img.is-loaded {
    opacity: 0; } }

.image {
  position: relative; }

.section-client {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 40px;
  box-sizing: border-box; }
  @media only screen and (max-width: 1024px) {
    .section-client {
      padding: 40px 0; } }
  .section-client svg {
    height: 90px;
    margin: auto; }
  .section-client .container {
    width: 100%;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .section-client .container .wrapper {
      width: 100%; }
      .section-client .container .wrapper svg {
        display: block; }
        @media only screen and (max-width: 576px) {
          .section-client .container .wrapper svg {
            width: 220px; } }
      .section-client .container .wrapper h3 {
        color: #927c60;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        font-size: 1.8em; }
      .section-client .container .wrapper .container-desktop .grid-inter {
        display: grid;
        grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
        grid-template-rows: 100px 100px;
        width: 60%;
        margin: auto;
        padding-bottom: 20px; }
        @media only screen and (max-width: 1024px) {
          .section-client .container .wrapper .container-desktop .grid-inter {
            width: 80%; } }
        .section-client .container .wrapper .container-desktop .grid-inter > div {
          position: relative; }
          .section-client .container .wrapper .container-desktop .grid-inter > div > img {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            height: 50%;
            max-width: 130px; }
            @media only screen and (max-width: 768px) {
              .section-client .container .wrapper .container-desktop .grid-inter > div > img {
                max-width: 80px; } }
        .section-client .container .wrapper .container-desktop .grid-inter .row-1 {
          grid-row: 1;
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat; }
          .section-client .container .wrapper .container-desktop .grid-inter .row-1.int1 {
            grid-column: 2; }
          .section-client .container .wrapper .container-desktop .grid-inter .row-1.int2 {
            grid-column: 4; }
          .section-client .container .wrapper .container-desktop .grid-inter .row-1.int3 {
            grid-column: 6; }
          .section-client .container .wrapper .container-desktop .grid-inter .row-1.int4 {
            grid-column: 8; }
        .section-client .container .wrapper .container-desktop .grid-inter .row-2 {
          grid-row: 2;
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat; }
          .section-client .container .wrapper .container-desktop .grid-inter .row-2.int5 {
            grid-column: 1; }
          .section-client .container .wrapper .container-desktop .grid-inter .row-2.int6 {
            grid-column: 3; }
          .section-client .container .wrapper .container-desktop .grid-inter .row-2.int7 {
            grid-column: 5; }
          .section-client .container .wrapper .container-desktop .grid-inter .row-2.int8 {
            grid-column: 7; }
          .section-client .container .wrapper .container-desktop .grid-inter .row-2.int9 {
            grid-column: 9; }
            .section-client .container .wrapper .container-desktop .grid-inter .row-2.int9 img {
              max-width: 75px; }
      .section-client .container .wrapper .container-desktop .grid-local {
        display: grid;
        grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
        grid-template-rows: 100px 100px 100px;
        width: 85%;
        margin: auto;
        padding-bottom: 20px; }
        @media only screen and (min-width: 1920px) {
          .section-client .container .wrapper .container-desktop .grid-local {
            width: 70%; } }
        @media only screen and (max-width: 1280px) {
          .section-client .container .wrapper .container-desktop .grid-local {
            width: 90%; } }
        .section-client .container .wrapper .container-desktop .grid-local > div {
          position: relative; }
          .section-client .container .wrapper .container-desktop .grid-local > div > img {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            width: auto;
            height: 45%;
            max-width: 90px; }
            @media only screen and (max-width: 768px) {
              .section-client .container .wrapper .container-desktop .grid-local > div > img {
                max-width: 65px; } }
        .section-client .container .wrapper .container-desktop .grid-local .row-1 {
          grid-row: 1;
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat; }
          .section-client .container .wrapper .container-desktop .grid-local .row-1.int1 {
            grid-column: 1; }
          .section-client .container .wrapper .container-desktop .grid-local .row-1.int2 {
            grid-column: 3; }
          .section-client .container .wrapper .container-desktop .grid-local .row-1.int3 {
            grid-column: 5; }
          .section-client .container .wrapper .container-desktop .grid-local .row-1.int4 {
            grid-column: 7; }
          .section-client .container .wrapper .container-desktop .grid-local .row-1.int5 {
            grid-column: 9; }
          .section-client .container .wrapper .container-desktop .grid-local .row-1.int6 {
            grid-column: 11; }
          .section-client .container .wrapper .container-desktop .grid-local .row-1.int7 {
            grid-column: 13; }
          .section-client .container .wrapper .container-desktop .grid-local .row-1.int8 {
            grid-column: 15; }
        .section-client .container .wrapper .container-desktop .grid-local .row-2 {
          grid-row: 2;
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat; }
          .section-client .container .wrapper .container-desktop .grid-local .row-2.int9 {
            grid-column: 2; }
          .section-client .container .wrapper .container-desktop .grid-local .row-2.int10 {
            grid-column: 4; }
          .section-client .container .wrapper .container-desktop .grid-local .row-2.int11 {
            grid-column: 6; }
          .section-client .container .wrapper .container-desktop .grid-local .row-2.int12 {
            grid-column: 8; }
          .section-client .container .wrapper .container-desktop .grid-local .row-2.int13 {
            grid-column: 10; }
          .section-client .container .wrapper .container-desktop .grid-local .row-2.int14 {
            grid-column: 12; }
          .section-client .container .wrapper .container-desktop .grid-local .row-2.int15 {
            grid-column: 14; }
        .section-client .container .wrapper .container-desktop .grid-local .row-3 {
          grid-row: 3;
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat; }
          .section-client .container .wrapper .container-desktop .grid-local .row-3.int16 {
            grid-column: 4; }
          .section-client .container .wrapper .container-desktop .grid-local .row-3.int17 {
            grid-column: 6; }
          .section-client .container .wrapper .container-desktop .grid-local .row-3.int18 {
            grid-column: 8; }
          .section-client .container .wrapper .container-desktop .grid-local .row-3.int19 {
            grid-column: 10; }
          .section-client .container .wrapper .container-desktop .grid-local .row-3.int20 {
            grid-column: 12; }
      .section-client .container .wrapper .container-mobile {
        display: none; }
        .section-client .container .wrapper .container-mobile .grid-inter-mobile,
        .section-client .container .wrapper .container-mobile .grid-local-mobile {
          display: -webkit-flex;
          display: flex;
          width: 100vw;
          margin: auto;
          box-sizing: border-box;
          overflow: hidden;
          padding-bottom: 30px; }
          .section-client .container .wrapper .container-mobile .grid-inter-mobile .container-1,
          .section-client .container .wrapper .container-mobile .grid-inter-mobile .container-2,
          .section-client .container .wrapper .container-mobile .grid-inter-mobile .container-3,
          .section-client .container .wrapper .container-mobile .grid-inter-mobile .container-4,
          .section-client .container .wrapper .container-mobile .grid-local-mobile .container-1,
          .section-client .container .wrapper .container-mobile .grid-local-mobile .container-2,
          .section-client .container .wrapper .container-mobile .grid-local-mobile .container-3,
          .section-client .container .wrapper .container-mobile .grid-local-mobile .container-4 {
            display: -webkit-flex;
            display: flex; }
            .section-client .container .wrapper .container-mobile .grid-inter-mobile .container-1 .animate,
            .section-client .container .wrapper .container-mobile .grid-inter-mobile .container-2 .animate,
            .section-client .container .wrapper .container-mobile .grid-inter-mobile .container-3 .animate,
            .section-client .container .wrapper .container-mobile .grid-inter-mobile .container-4 .animate,
            .section-client .container .wrapper .container-mobile .grid-local-mobile .container-1 .animate,
            .section-client .container .wrapper .container-mobile .grid-local-mobile .container-2 .animate,
            .section-client .container .wrapper .container-mobile .grid-local-mobile .container-3 .animate,
            .section-client .container .wrapper .container-mobile .grid-local-mobile .container-4 .animate {
              width: 150px;
              padding: 0 10px;
              display: -webkit-inline-flex;
              display: inline-flex;
              -webkit-align-items: center;
                      align-items: center;
              -webkit-justify-content: center;
                      justify-content: center;
              transition: all .2s ease; }
              .section-client .container .wrapper .container-mobile .grid-inter-mobile .container-1 .animate img,
              .section-client .container .wrapper .container-mobile .grid-inter-mobile .container-2 .animate img,
              .section-client .container .wrapper .container-mobile .grid-inter-mobile .container-3 .animate img,
              .section-client .container .wrapper .container-mobile .grid-inter-mobile .container-4 .animate img,
              .section-client .container .wrapper .container-mobile .grid-local-mobile .container-1 .animate img,
              .section-client .container .wrapper .container-mobile .grid-local-mobile .container-2 .animate img,
              .section-client .container .wrapper .container-mobile .grid-local-mobile .container-3 .animate img,
              .section-client .container .wrapper .container-mobile .grid-local-mobile .container-4 .animate img {
                width: 100%;
                max-width: 90px;
                max-height: 60px; }
          .section-client .container .wrapper .container-mobile .grid-inter-mobile .container-1,
          .section-client .container .wrapper .container-mobile .grid-local-mobile .container-1 {
            -webkit-animation: lineSlider 10s linear infinite running;
                    animation: lineSlider 10s linear infinite running;
            -webkit-animation-duration: 53s;
                    animation-duration: 53s; }
          .section-client .container .wrapper .container-mobile .grid-inter-mobile .container-2,
          .section-client .container .wrapper .container-mobile .grid-local-mobile .container-2 {
            -webkit-animation: lineSlider 20s linear infinite running;
                    animation: lineSlider 20s linear infinite running;
            -webkit-animation-duration: 25s;
                    animation-duration: 25s; }
          .section-client .container .wrapper .container-mobile .grid-inter-mobile .container-3,
          .section-client .container .wrapper .container-mobile .grid-local-mobile .container-3 {
            -webkit-animation: lineSlider 10s linear infinite running;
                    animation: lineSlider 10s linear infinite running;
            -webkit-animation-duration: 40s;
                    animation-duration: 40s; }
          .section-client .container .wrapper .container-mobile .grid-inter-mobile .container-4,
          .section-client .container .wrapper .container-mobile .grid-local-mobile .container-4 {
            -webkit-animation: lineSlider 10s linear infinite running;
                    animation: lineSlider 10s linear infinite running;
            -webkit-animation-duration: 30s;
                    animation-duration: 30s; }
      @media only screen and (max-width: 1024px) {
        .section-client .container .wrapper .container-desktop {
          display: none; }
        .section-client .container .wrapper .container-mobile {
          display: block; } }
  .section-client .hidden {
    overflow: hidden; }
    .section-client .hidden h3 {
      opacity: 1;
      overflow: hidden; }

@-webkit-keyframes lineSlider {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); } }

@keyframes lineSlider {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); } }

@media only screen and (max-width: 992px) and (orientation: landscape) {
  .section-client {
    padding: 40px 0; }
    .section-client svg {
      margin: auto;
      width: 40%; }
    .section-client .container-desktop {
      display: none; }
    .section-client .container-mobile {
      display: block !important;
      overflow: hidden; }
      .section-client .container-mobile h3 {
        font-size: 1.4em !important; } }

.section-contact {
  width: 100%;
  height: 80vh;
  position: relative; }
  .section-contact ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .section-contact li,
  .section-contact a {
    font-size: 14px;
    white-space: nowrap;
    color: #989898;
    font-weight: 400;
    font-family: gill-sans-nova, sans-serif;
    letter-spacing: 0.2px; }
  .section-contact a {
    text-decoration: none; }
  .section-contact h2 {
    color: #927c60;
    position: absolute;
    letter-spacing: 3.5px;
    margin: 0;
    text-transform: uppercase; }
  .section-contact .container {
    width: 100%;
    height: 80vh;
    display: -webkit-flex;
    display: flex; }
    .section-contact .container .wrapper-description {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      position: relative;
      width: 60%;
      margin: auto; }
      @media only screen and (max-width: 1280px) {
        .section-contact .container .wrapper-description {
          width: 65%; } }
      @media only screen and (max-width: 1140px) {
        .section-contact .container .wrapper-description {
          width: 75%; } }
      .section-contact .container .wrapper-description svg {
        width: 100%; }
      .section-contact .container .wrapper-description h2 {
        font-size: 3.5vw; }
      .section-contact .container .wrapper-description .plusCon {
        width: 1vw;
        position: absolute;
        top: -7%;
        left: -3%;
        opacity: 0; }
      @media only screen and (max-width: 992px) {
        .section-contact .container .wrapper-description li,
        .section-contact .container .wrapper-description a {
          font-size: 1.5vw;
          line-height: 1.8vw; }
        .section-contact .container .wrapper-description h2 {
          font-size: 30px; } }
      .section-contact .container .wrapper-description .new-york,
      .section-contact .container .wrapper-description .bangkok,
      .section-contact .container .wrapper-description .facebook {
        position: absolute;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto;
        grid-column-gap: 15px; }
      .section-contact .container .wrapper-description .new-york {
        right: 7vw;
        top: -30px; }
        @media only screen and (max-width: 992px) {
          .section-contact .container .wrapper-description .new-york {
            right: 4vw;
            top: -25px; } }
        .section-contact .container .wrapper-description .new-york .plusNy {
          grid-column: 1;
          grid-row: 1;
          width: 1vw;
          opacity: 0;
          margin-left: -1vw; }
        .section-contact .container .wrapper-description .new-york .nyText {
          grid-row: 2; }
          .section-contact .container .wrapper-description .new-york .nyText-1 {
            grid-column: 1;
            margin: auto 0 auto auto;
            position: relative;
            opacity: 0; }
          .section-contact .container .wrapper-description .new-york .nyText-2 {
            grid-column: 2;
            margin: auto auto auto 0;
            position: relative;
            opacity: 0; }
        .section-contact .container .wrapper-description .new-york ul.nyContactLeft {
          grid-row: 3;
          grid-column: 1;
          margin: auto 0 auto auto;
          position: relative;
          text-align: right; }
        .section-contact .container .wrapper-description .new-york ul.nyContactRight {
          grid-row: 3;
          grid-column: 2;
          margin: auto auto auto 0;
          position: relative;
          text-align: left; }
      .section-contact .container .wrapper-description .bangkok {
        bottom: -80px;
        left: 20px; }
        @media only screen and (max-width: 1280px) {
          .section-contact .container .wrapper-description .bangkok {
            left: -20px; } }
        @media only screen and (max-width: 992px) {
          .section-contact .container .wrapper-description .bangkok {
            left: -50px;
            bottom: -55px; } }
        .section-contact .container .wrapper-description .bangkok .bkkText {
          grid-column: 1/3;
          grid-row: 2;
          opacity: 0;
          margin: 0 auto 10px;
          position: relative; }
        .section-contact .container .wrapper-description .bangkok .plusBkk {
          width: 1vw;
          grid-row: 1;
          grid-column: 1;
          opacity: 0; }
        .section-contact .container .wrapper-description .bangkok .bkkContactLeft {
          grid-row: 3;
          grid-column: 1;
          margin-top: 0;
          position: relative;
          text-align: right; }
        .section-contact .container .wrapper-description .bangkok .bkkContactRight {
          grid-row: 3;
          grid-column: 2;
          margin: auto auto auto 0;
          position: relative; }
      .section-contact .container .wrapper-description .facebook {
        right: 17vw;
        bottom: -100px; }
        @media only screen and (max-width: 992px) {
          .section-contact .container .wrapper-description .facebook {
            right: 15vw;
            bottom: -90px; } }
        .section-contact .container .wrapper-description .facebook .plusFb {
          width: 1vw;
          grid-column: 1;
          grid-row: 1;
          opacity: 0;
          position: relative;
          margin-left: -2vw; }
        .section-contact .container .wrapper-description .facebook .fbIcon {
          grid-column: 1;
          grid-row: 2;
          opacity: 0;
          margin-top: auto;
          position: relative;
          width: 35px; }
        .section-contact .container .wrapper-description .facebook .fbContact {
          grid-column: 2;
          grid-row: 2;
          margin: 0 auto;
          position: relative;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column;
          -webkit-justify-content: center;
                  justify-content: center; }
      .section-contact .container .wrapper-description .container-contact {
        display: -webkit-flex;
        display: flex;
        position: absolute; }
        .section-contact .container .wrapper-description .container-contact .wrapper {
          width: 100%; }
        .section-contact .container .wrapper-description .container-contact.ny {
          width: 40%;
          top: 15%;
          right: 6.5vw; }
        .section-contact .container .wrapper-description .container-contact.bkk {
          width: 40%;
          top: 100%;
          left: 1.8vw; }
    .section-contact .container .wrapper-description-mobile {
      display: none;
      width: 100%;
      position: relative;
      margin: auto; }
      .section-contact .container .wrapper-description-mobile h2 {
        position: relative;
        font-size: 2.5em; }
      .section-contact .container .wrapper-description-mobile svg {
        width: auto;
        position: absolute;
        top: -40%;
        right: 140px;
        -webkit-transform: translate(-50%, -50%);
        transform: rotate(270deg);
        -webkit-transform-origin: right top;
                transform-origin: right top;
        height: 140px; }
      .section-contact .container .wrapper-description-mobile .wrapper {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-around;
                justify-content: space-around;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box; }
        .section-contact .container .wrapper-description-mobile .wrapper div.img-contact img {
          width: 250px;
          position: relative;
          margin: auto;
          display: block; }
        .section-contact .container .wrapper-description-mobile .wrapper > div {
          padding-bottom: 30px; }
          .section-contact .container .wrapper-description-mobile .wrapper > div:last-child {
            padding-bottom: 0; }
        .section-contact .container .wrapper-description-mobile .wrapper div.facebook {
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center; }
          .section-contact .container .wrapper-description-mobile .wrapper div.facebook ul {
            padding-left: 15px; }
          .section-contact .container .wrapper-description-mobile .wrapper div.facebook img {
            width: 25px;
            position: relative;
            margin: auto;
            display: block; }
    @media only screen and (max-width: 576px) {
      .section-contact .container .wrapper-description {
        display: none; }
      .section-contact .container .wrapper-description-mobile {
        display: block; } }
    .section-contact .container .hidden {
      margin-bottom: 7.5vw;
      margin-bottom: calc(30*100vw/var(--size));
      overflow: hidden; }
      .section-contact .container .hidden li {
        overflow: hidden;
        opacity: 1; }

@media only screen and (max-width: 992px) and (orientation: landscape) {
  .section-contact {
    height: unset; }
    .section-contact .container {
      height: unset;
      padding: 40px 0; }
      .section-contact .container .wrapper-description {
        display: none; }
      .section-contact .container .wrapper-description-mobile {
        display: block; } }

.section-jobs {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 3;
  position: relative; }
  .section-jobs h1 {
    color: #808080;
    font-size: 3vw;
    padding-bottom: 20px;
    margin: 0;
    text-align: center; }
    @media only screen and (max-width: 576px) {
      .section-jobs h1 {
        font-size: 7vw; } }
  .section-jobs h4 {
    margin: 0;
    color: #927c60;
    font-size: 18px;
    margin-bottom: 50px; }
    @media only screen and (max-width: 576px) {
      .section-jobs h4 {
        margin-bottom: 20px; } }
  .section-jobs h5,
  .section-jobs label {
    padding-bottom: 5px;
    margin: 0;
    color: #808080;
    line-height: 10px; }
    @media only screen and (max-width: 576px) {
      .section-jobs h5,
      .section-jobs label {
        padding-bottom: 10px; } }
  .section-jobs p {
    font-weight: 400;
    color: #808080;
    margin: 0; }
  .section-jobs h5,
  .section-jobs span,
  .section-jobs p {
    font-size: 14px; }
  .section-jobs .container {
    width: 100%;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    padding: 40px;
    box-sizing: border-box; }
    .section-jobs .container .register-form {
      width: 100%;
      max-width: 900px;
      position: relative;
      margin: auto;
      z-index: 1; }
      .section-jobs .container .register-form .text-header {
        width: 80%;
        display: block;
        margin: auto;
        padding-bottom: 20px; }
        @media only screen and (max-width: 576px) {
          .section-jobs .container .register-form .text-header {
            width: 90%; } }
        .section-jobs .container .register-form .text-header img {
          width: 100%; }
      .section-jobs .container .register-form .wrapper {
        width: 100%;
        display: -webkit-flex;
        display: flex; }
        @media only screen and (max-width: 576px) {
          .section-jobs .container .register-form .wrapper {
            -webkit-flex-direction: column;
                    flex-direction: column; } }
        .section-jobs .container .register-form .wrapper .box-left {
          width: 30%; }
          .section-jobs .container .register-form .wrapper .box-left ul {
            padding: 0;
            list-style: none; }
            .section-jobs .container .register-form .wrapper .box-left ul li {
              margin-bottom: 20px; }
          .section-jobs .container .register-form .wrapper .box-left .container-header .wrapper-header {
            position: relative; }
            .section-jobs .container .register-form .wrapper .box-left .container-header .wrapper-header img {
              width: 13px;
              position: absolute;
              top: -13px;
              left: -13px; }
          @media only screen and (max-width: 576px) {
            .section-jobs .container .register-form .wrapper .box-left .container-wrapper {
              display: -webkit-flex;
              display: flex;
              -webkit-justify-content: center;
                      justify-content: center;
              text-align: center; } }
        .section-jobs .container .register-form .wrapper .box-right {
          width: 75%;
          padding: 10px; }
          .section-jobs .container .register-form .wrapper .box-right .wrapper {
            width: 100%;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
                    flex-direction: column; }
            .section-jobs .container .register-form .wrapper .box-right .wrapper .row {
              display: -webkit-flex;
              display: flex;
              -webkit-flex-wrap: wrap;
                      flex-wrap: wrap;
              padding-bottom: 10px; }
              .section-jobs .container .register-form .wrapper .box-right .wrapper .row:nth-child(4) .col-1, .section-jobs .container .register-form .wrapper .box-right .wrapper .row:nth-child(5) .col-1 {
                padding-bottom: 5px; }
              @media only screen and (max-width: 576px) {
                .section-jobs .container .register-form .wrapper .box-right .wrapper .row {
                  padding-bottom: 0; } }
              .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-1 {
                width: 100%;
                box-sizing: border-box;
                padding: 0 5px 5px; }
                .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-1 .element {
                  display: -webkit-flex;
                  display: flex; }
                  @media only screen and (max-width: 576px) {
                    .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-1 .element {
                      -webkit-flex-direction: column;
                              flex-direction: column; }
                      .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-1 .element .wrapper {
                        padding-bottom: 10px; } }
                  .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-1 .element .col-2 {
                    /* Show the checkmark when checked */
                    /* Style the checkmark/indicator */ }
                    .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-1 .element .col-2 .container-label {
                      display: block;
                      position: relative;
                      padding-left: 35px;
                      padding-top: 8px;
                      margin-bottom: 12px;
                      font-size: 14px;
                      padding-bottom: 0;
                      -webkit-user-select: none;
                      -moz-user-select: none;
                      -ms-user-select: none;
                      user-select: none; }
                    .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-1 .element .col-2 .container-label input {
                      position: absolute;
                      opacity: 0;
                      height: 0;
                      width: 0; }
                    .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-1 .element .col-2 .checkmark {
                      position: absolute;
                      top: 0;
                      left: 0;
                      height: 25px;
                      width: 25px;
                      background-color: transparent;
                      border: 1px solid #927c60; }
                    .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-1 .element .col-2 .checkmark:after {
                      content: "";
                      position: absolute;
                      display: none; }
                    .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-1 .element .col-2 .container-label input:checked ~ .checkmark:after {
                      display: block; }
                    .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-1 .element .col-2 .container-label .checkmark:after {
                      left: 9px;
                      top: 5px;
                      width: 5px;
                      height: 10px;
                      border: 1px solid #927c60;
                      border-width: 0 3px 3px 0;
                      -webkit-transform: rotate(45deg);
                      transform: rotate(45deg); }
              .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-2 {
                width: 50%;
                box-sizing: border-box;
                padding: 0 5px 5px; }
                @media only screen and (max-width: 576px) {
                  .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-2 {
                    width: 100%; } }
              .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-3 {
                width: 100%;
                display: -webkit-flex;
                display: flex;
                box-sizing: border-box;
                padding: 0 5px 5px; }
                .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-3 .wrapper {
                  width: 10%;
                  text-align: center;
                  margin: auto; }
                  .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-3 .wrapper span {
                    color: #808080; }
                @media only screen and (max-width: 576px) {
                  .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-3 {
                    -webkit-justify-content: center;
                            justify-content: center; }
                    .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-3 button {
                      width: 45%; } }
              @media only screen and (max-width: 576px) {
                .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-1, .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-2, .section-jobs .container .register-form .wrapper .box-right .wrapper .row .col-3 {
                  padding: 0 5px 15px; } }
              .section-jobs .container .register-form .wrapper .box-right .wrapper .row label span {
                margin: 0;
                color: #808080; }
                .section-jobs .container .register-form .wrapper .box-right .wrapper .row label span:last-child {
                  font-family: gill-sans-nova, sans-serif;
                  font-weight: 400; }
              .section-jobs .container .register-form .wrapper .box-right .wrapper .row button {
                border: 1px solid #927c60;
                width: 25%;
                height: 30px;
                outline: none;
                font-size: 14px; }
                .section-jobs .container .register-form .wrapper .box-right .wrapper .row button.btn-upload {
                  color: #808080;
                  font-weight: 400;
                  background: transparent; }
                .section-jobs .container .register-form .wrapper .box-right .wrapper .row button.btn-submit {
                  background: #927c60;
                  color: #333333; }
              .section-jobs .container .register-form .wrapper .box-right .wrapper .row input[type=text] {
                width: 100%;
                font-family: gill-sans-nova, sans-serif;
                font-weight: 400;
                font-size: 16px;
                border: 1px solid #927c60;
                color: #927c60;
                box-sizing: border-box;
                height: 30px;
                padding: 0 0 0 10px;
                outline: none;
                background: transparent;
                -webkit-appearance: none;
                border-radius: 0; }
        @media only screen and (max-width: 576px) {
          .section-jobs .container .register-form .wrapper .box-left,
          .section-jobs .container .register-form .wrapper .box-right {
            width: 100%; } }
  @media only screen and (max-width: 576px) {
    .section-jobs {
      height: 100%;
      overflow: auto; }
      .section-jobs .container {
        padding: 80px 20px 80px;
        height: 100%; } }

.navigation .main-nav__canvas {
  display: none; }

.navigation .main-nav__canvas {
  display: block;
  height: 100vh;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100; }

.navigation #main-nav-toggle {
  position: absolute;
  visibility: hidden; }
  .navigation #main-nav-toggle:checked ~ nav.main-nav {
    display: -webkit-flex;
    display: flex;
    opacity: 1;
    pointer-events: all;
    z-index: 4; }
  .navigation #main-nav-toggle:checked ~ nav.main-nav-mobile {
    display: -webkit-flex;
    display: flex;
    opacity: 1;
    pointer-events: all; }

.navigation .box-btn {
  height: 45px;
  width: 45px;
  position: fixed;
  z-index: 5;
  margin: 20px;
  top: 0; }
  .navigation .box-btn .group-btn {
    z-index: 5;
    width: 100%;
    height: 100%;
    position: absolute; }
  .navigation .box-btn nav.main-nav {
    transition: 0.3s opacity;
    -webkit-align-items: center;
            align-items: center;
    background: #151515;
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    left: 0px;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1; }
  .navigation .box-btn nav.main-nav {
    display: none; }

@media only screen and (max-width: 576px) {
  .navigation .wrapper-header {
    padding: 15px; } }

.navigation .wrapper-header .box-logo {
  max-width: 150px; }
  @media only screen and (max-width: 992px) {
    .navigation .wrapper-header .box-logo {
      max-width: 130px; } }
  @media only screen and (max-width: 576px) {
    .navigation .wrapper-header .box-logo {
      max-width: 100px; } }
  .navigation .wrapper-header .box-logo img {
    width: 100%;
    transition: all 0.6s ease-in-out; }

nav.main-nav-mobile {
  display: none; }

nav.main-nav-mobile {
  -webkit-align-items: center;
          align-items: center;
  background: #efefef;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  opacity: 0;
  position: fixed;
  z-index: 4;
  pointer-events: none;
  top: 0;
  text-align: center;
  display: table;
  transition: opacity 1.2s ease-out; }
  nav.main-nav-mobile.active {
    opacity: 1;
    pointer-events: all;
    transition: opacity .5s ease-out; }
  nav.main-nav-mobile .wrapper {
    width: 100%;
    height: 100vh;
    z-index: 9;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    background: #151515;
    opacity: 0;
    transition: opacity .3s ease-out; }
    @media only screen and (max-width: 576px) {
      nav.main-nav-mobile .wrapper {
        vertical-align: top; } }
    nav.main-nav-mobile .wrapper .wrapper-data {
      display: block;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      height: calc(100vh - 170px);
      -webkit-mask-image: linear-gradient(180deg, transparent 0, transparent 1%, #000 20%, #000 50%, #000 80%, transparent); }
      nav.main-nav-mobile .wrapper .wrapper-data div.container-link {
        padding: 100px 48px 40px; }
        nav.main-nav-mobile .wrapper .wrapper-data div.container-link div.box-link {
          text-align: center;
          letter-spacing: .2rem; }
          nav.main-nav-mobile .wrapper .wrapper-data div.container-link div.box-link a.item-link {
            color: #989898;
            margin: 50px 0;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
                    flex-direction: column;
            transition: opacity .5s ease; }
            nav.main-nav-mobile .wrapper .wrapper-data div.container-link div.box-link a.item-link .title {
              font-size: 32px;
              font-weight: 100;
              margin-bottom: 5px; }
            nav.main-nav-mobile .wrapper .wrapper-data div.container-link div.box-link a.item-link .title-number {
              font-size: 14px;
              font-weight: 100;
              opacity: .7; }

@media only screen and (max-width: 992px) and (orientation: landscape) {
  nav.main-nav-mobile .wrapper-data {
    height: 100vh;
    overflow-y: scroll; }
    nav.main-nav-mobile .wrapper-data .container-link {
      padding: 100px 48px 40px; } }

.section-exceptionalwork {
  width: 100%;
  height: 100%;
  z-index: 3;
  position: relative; }
  .section-exceptionalwork .portfolios {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
  .section-exceptionalwork .hide {
    display: none; }
  .section-exceptionalwork h1 {
    color: #808080;
    font-size: 2.5em; }
    @media only screen and (max-width: 576px) {
      .section-exceptionalwork h1 {
        font-size: 2em; } }
  .section-exceptionalwork .box-1 {
    height: 130px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    overflow: hidden;
    position: fixed;
    z-index: 1;
    top: 0;
    background: #000; }
  .section-exceptionalwork .box-1-mobile {
    display: none; }
  @media only screen and (max-width: 992px) {
    .section-exceptionalwork .box-1 {
      display: none; }
    .section-exceptionalwork .box-1-mobile {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      width: 100%;
      height: 210px; }
      .section-exceptionalwork .box-1-mobile .container-text-front {
        width: 100%;
        height: 150px;
        background-color: #000;
        position: relative;
        z-index: 3;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center; }
        .section-exceptionalwork .box-1-mobile .container-text-front h1 {
          margin: 0;
          font-size: 2.5em;
          opacity: 1;
          transition: opacity .3s ease-in-out; } }
      @media only screen and (max-width: 992px) and (max-width: 576px) {
        .section-exceptionalwork .box-1-mobile .container-text-front h1 {
          margin-top: 50px; } }
  @media only screen and (max-width: 992px) {
        .section-exceptionalwork .box-1-mobile .container-text-front.gone h1 {
          opacity: 0; }
      .section-exceptionalwork .box-1-mobile .container-text-back {
        width: 100%;
        height: 100px;
        background-color: #000;
        position: fixed;
        top: 0;
        z-index: 2;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        transition: 1s all ease; }
        .section-exceptionalwork .box-1-mobile .container-text-back h1 {
          font-size: 2em;
          margin: 0;
          opacity: 0; }
        .section-exceptionalwork .box-1-mobile .container-text-back.display h1 {
          opacity: 1;
          transition: all .5s ease-out; }
      .section-exceptionalwork .box-1-mobile .container-mobile {
        height: 60px;
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background: #000;
        overflow: hidden; }
        .section-exceptionalwork .box-1-mobile .container-mobile.display {
          position: fixed;
          top: 100px;
          z-index: 2; } }
  .section-exceptionalwork .box-2 .box-left {
    background: #000;
    width: 20%;
    height: calc(100% - 130px);
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0; }
    .section-exceptionalwork .box-2 .box-left .container {
      text-align: left; }
      .section-exceptionalwork .box-2 .box-left .container h1 {
        bottom: 0;
        position: absolute;
        color: #927C60; }
      .section-exceptionalwork .box-2 .box-left .container div.text-header {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .section-exceptionalwork .box-2 .box-left .container div.text-header h4 {
          margin: 0;
          padding-left: 20px; }
        .section-exceptionalwork .box-2 .box-left .container div.text-header img {
          max-width: 13px; }
      .section-exceptionalwork .box-2 .box-left .container p {
        color: #989898; }
  .section-exceptionalwork .box-2 .box-right {
    width: 80%;
    position: relative;
    margin-left: auto;
    margin-top: 130px; }
  @media only screen and (max-width: 992px) {
    .section-exceptionalwork .box-2 .box-left {
      display: none; }
    .section-exceptionalwork .box-2 .box-right {
      width: 100%;
      margin-top: unset; } }
  .section-exceptionalwork h1 {
    text-align: left; }
  .section-exceptionalwork h4 {
    color: #927c60;
    font-size: 1.3em;
    font-weight: 100; }
  .section-exceptionalwork p {
    text-align: justify; }
  .section-exceptionalwork ul {
    list-style: none;
    padding: 0;
    padding-left: 10px; }
    .section-exceptionalwork ul p {
      font-weight: 500;
      color: #3a3a3a;
      margin: 0;
      letter-spacing: 1px; }
  .section-exceptionalwork ul.categories {
    padding-left: 15px;
    margin: 0;
    height: 100%;
    -webkit-align-items: center;
            align-items: center; }
    .section-exceptionalwork ul.categories li a {
      font-weight: 500;
      color: #3a3a3a;
      margin: 0;
      letter-spacing: 1px; }
      .section-exceptionalwork ul.categories li a.active {
        color: #989898; }
      .section-exceptionalwork ul.categories li a:hover {
        color: #989898; }
    @media only screen and (max-width: 992px) {
      .section-exceptionalwork ul.categories {
        padding-left: 0;
        display: -webkit-flex;
        display: flex;
        overflow-x: scroll; }
        .section-exceptionalwork ul.categories li {
          padding: 0 10px; }
          .section-exceptionalwork ul.categories li a {
            white-space: nowrap; } }
    @media only screen and (max-width: 576px) {
      .section-exceptionalwork ul.categories {
        padding-left: 20px;
        padding-right: 20px;
        -webkit-mask-image: linear-gradient(90deg, transparent 0, transparent 1%, #000 20%, #000 50%, #000 90%, transparent); }
        .section-exceptionalwork ul.categories li:first-child {
          padding-left: 30px; }
        .section-exceptionalwork ul.categories li:last-child {
          padding-right: 30px; } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .section-exceptionalwork .hidden {
    overflow: hidden; }
    .section-exceptionalwork .hidden span {
      opacity: 1; }
    .section-exceptionalwork .hidden h1 {
      opacity: 1; }

.grid-exceptionalwork {
  display: block;
  width: 100%; }
  .grid-exceptionalwork .thumbnail {
    display: block;
    float: left;
    width: 25%;
    height: calc((100vw/5) * .75);
    position: relative;
    transition: all .6s ease-out;
    opacity: 0;
    -webkit-animation: fadeIn .6s forwards .2s;
            animation: fadeIn .6s forwards .2s; }
    @media only screen and (max-width: 576px) {
      .grid-exceptionalwork .thumbnail {
        width: 50%;
        height: calc((100vw/2) * .75); } }
    .grid-exceptionalwork .thumbnail img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity .8s ease-in-out; }
    .grid-exceptionalwork .thumbnail:hover {
      background: transparent; }
      .grid-exceptionalwork .thumbnail:hover img {
        opacity: 0; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

section.section-whatwedo {
  width: 100%;
  height: 100vh;
  padding: 40px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative; }
  section.section-whatwedo ul {
    list-style: none;
    padding: 0;
    margin: 0; }
  section.section-whatwedo h1 {
    font-size: 3em;
    letter-spacing: 4px;
    margin: 0; }
  section.section-whatwedo hr {
    margin: 50px auto;
    width: 30%;
    border: 1px solid #808080; }
  section.section-whatwedo p {
    color: #989898;
    font-weight: 300;
    letter-spacing: 1.2px;
    line-height: 1.5em;
    font-size: 14px;
    margin: 0px 0 25px;
    white-space: pre-line; }
  @media only screen and (max-width: 576px) {
    section.section-whatwedo {
      padding: 60px 20px; }
      section.section-whatwedo h1 {
        font-size: 2.2em; }
      section.section-whatwedo ul div:nth-child(1) p {
        white-space: normal !important; } }
  section.section-whatwedo .container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative; }
    section.section-whatwedo .container .box-left {
      width: 50%;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      padding-right: 60px;
      z-index: 1; }
      section.section-whatwedo .container .box-left svg {
        max-width: 400px;
        width: 100%; }
        @media only screen and (max-width: 576px) {
          section.section-whatwedo .container .box-left svg {
            max-width: 250px;
            width: 100%; } }
    section.section-whatwedo .container span.space {
      position: absolute;
      width: 100%;
      height: 1px;
      background: #927c60;
      top: 50%;
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: center;
              transform-origin: center; }
    section.section-whatwedo .container .box-right {
      width: 50%;
      padding-left: 20px; }
      section.section-whatwedo .container .box-right span:nth-child(1) {
        color: #808080; }
      section.section-whatwedo .container .box-right span:nth-child(2) {
        color: #927c60; }
      section.section-whatwedo .container .box-right p {
        margin-left: 110px; }
      section.section-whatwedo .container .box-right div:nth-child(1) {
        margin-left: -120px; }
        section.section-whatwedo .container .box-right div:nth-child(1) p {
          margin-left: 120px; }
      section.section-whatwedo .container .box-right div:nth-child(2) {
        margin-left: 0px; }
        section.section-whatwedo .container .box-right div:nth-child(2) p {
          margin-left: 101px; }
        @media only screen and (min-width: 577px) {
          section.section-whatwedo .container .box-right div:nth-child(2) p {
            white-space: normal !important; } }
      section.section-whatwedo .container .box-right div:nth-child(3) {
        margin-left: 100px; }
      @media only screen and (max-width: 1024px) {
        section.section-whatwedo .container .box-right div.hidden {
          margin-left: 0; } }
    @media only screen and (max-width: 1024px) {
      section.section-whatwedo .container {
        -webkit-flex-direction: column;
                flex-direction: column; }
        section.section-whatwedo .container .box-left,
        section.section-whatwedo .container .box-right {
          width: 100%;
          padding: 0; }
        section.section-whatwedo .container span.space {
          display: none; }
        section.section-whatwedo .container .box-left {
          -webkit-justify-content: center;
                  justify-content: center;
          margin: 50px 0; }
        section.section-whatwedo .container .box-right {
          text-align: center; }
          section.section-whatwedo .container .box-right p,
          section.section-whatwedo .container .box-right li {
            margin: 0 !important; }
          section.section-whatwedo .container .box-right li {
            padding: 0px 0 40px; } }
    @media only screen and (max-width: 576px) {
      section.section-whatwedo .container .box-left {
        margin: 0; } }
  section.section-whatwedo .hidden {
    overflow: hidden; }
    section.section-whatwedo .hidden li {
      overflow: hidden;
      opacity: 1; }

@media only screen and (max-width: 992px) and (orientation: landscape) {
  section.section-whatwedo {
    height: unset; }
    section.section-whatwedo h1 {
      font-size: 2em; }
    section.section-whatwedo .container .box-left {
      margin: 20px 0; }
      section.section-whatwedo .container .box-left svg {
        max-width: 300px;
        width: 100%; } }

section.section-whyus {
  width: 100%;
  height: 100vh;
  padding: 40px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  @media only screen and (max-width: 992px) and (orientation: landscape) {
    section.section-whyus {
      height: unset; }
      section.section-whyus h1 {
        font-size: 5em; }
      section.section-whyus h2 {
        font-size: 1.2em; } }
  section.section-whyus h1 {
    font-size: 6em;
    letter-spacing: 4px;
    margin: 0 0 50px; }
  section.section-whyus h2 {
    color: #927c60;
    white-space: pre-line;
    letter-spacing: 1.5px;
    margin: 0; }
  section.section-whyus hr {
    margin: 50px auto;
    width: 0%;
    border: 1px solid #808080;
    opacity: 0; }
  section.section-whyus p {
    color: #989898;
    font-weight: 300;
    letter-spacing: 1.2px;
    line-height: 1.5em;
    font-size: 14px; }
  @media only screen and (max-width: 576px) {
    section.section-whyus {
      padding: 60px 20px; }
      section.section-whyus hr {
        margin: 40px auto; }
      section.section-whyus p {
        font-size: 12px; } }
  section.section-whyus .container {
    text-align: center; }
  section.section-whyus .hidden {
    overflow: hidden; }
    section.section-whyus .hidden li {
      overflow: hidden;
      opacity: 1; }

