.grid-exceptionalwork {
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    // grid-auto-rows: calc(80vw / 5);
    display: block;
    width: 100%;

    // @media only screen and (max-width: 992px) {
    //     grid-template-columns: repeat(2, 1fr);
    //     grid-auto-rows: calc(100vw / 2);
    // }
    // @media only screen and (max-width: 576px) {
    //     grid-template-columns: repeat(2, 1fr);
    //     grid-auto-rows: calc(100vw / 2);
    // }

    .thumbnail {
        display: block;
        // width: 100%;
        // height: 100%;
        float: left;
        width: 25%;
        height: calc((100vw/5) * .75);
        position: relative;
        transition: all .6s ease-out;
        opacity: 0;
        animation: fadeIn .6s forwards .2s;
        // border: 1px solid #000;

        @media only screen and (max-width: 576px) {
            width: 50%;
            height: calc((100vw/2) * .75);
        }
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: opacity .8s ease-in-out;
        }
        &:hover {
            background: transparent;
            img {
                opacity: 0;
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
}
