.section-jobs {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 3;
    position: relative;

    h1 {
        color: #808080;
        font-size: 3vw;
        padding-bottom: 20px;
        margin: 0;
        text-align: center;

        @media only screen and (max-width: 576px) {
            font-size: 7vw;
        }
         
    }

    h4 {
        margin: 0;
        color: #927c60;
        font-size: 18px;
        margin-bottom: 50px;

        @media only screen and (max-width: 576px) {
            margin-bottom: 20px;
        }
    }

    h5,
    label {
        padding-bottom: 5px;
        margin: 0;
        color: #808080;
        line-height: 10px;
        @media only screen and (max-width: 576px) {
            padding-bottom: 10px;
        }
    }

    p {

        font-weight: 400;
        color: #808080;
        margin: 0;
    }


    h5,
    span,
    p {
        font-size: 14px;
    }

    .container {
        width: 100%;
        height: 100vh;
        display: flex;
        padding: 40px;
        box-sizing: border-box;

        .register-form {
            width: 100%;
            max-width: 900px;
            position: relative;
            margin: auto;
            z-index: 1;

            .text-header {
                width: 80%;
                display: block;
                margin: auto;
                padding-bottom: 20px;

                @media only screen and (max-width: 576px) {
                    width: 90%;
                }

                img {
                    width: 100%;
                }
            }

            .wrapper {
                width: 100%;
                display: flex;

                @media only screen and (max-width: 576px) {
                    flex-direction: column;
                }


                .box-left {
                    width: 30%;

                    ul {
                        padding: 0;
                        list-style: none;

                        li {
                            margin-bottom: 20px;
                        }
                    }

                    .container-header {
                        .wrapper-header {
                            position: relative;
                            img {
                                width: 13px;
                                position: absolute;
                                top: -13px;
                                left: -13px;
                            }
                        }
                    }

                    @media only screen and (max-width: 576px) {
                        .container-wrapper {
                            display: flex;
                            justify-content: center;
                            text-align: center;
                        }
                    }

                }

                .box-right {
                    width: 75%;
                    padding: 10px;


                    .wrapper {
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        .row {
                            display: flex;
                            flex-wrap: wrap;
                            padding-bottom: 10px;

                            &:nth-child(4), &:nth-child(5) {
                                .col-1 {
                                    padding-bottom: 5px;
                                }
                            }


                            @media only screen and (max-width: 576px)  {
                                padding-bottom: 0;
                            }

                            .col-1 {
                                width: 100%;
                                box-sizing: border-box;
                                padding: 0 5px 5px;

                                .element {
                                    display: flex;

                                    @media only screen and (max-width: 576px) {
                                        flex-direction: column;

                                        .wrapper {
                                            padding-bottom: 10px;
                                        }
                                    }

                                    .col-2 {
                                        .container-label {
                                            display: block;
                                            position: relative;
                                            padding-left: 35px;
                                            padding-top: 8px;
                                            margin-bottom: 12px;
                                            font-size: 14px;
                                            padding-bottom: 0;
                                            -webkit-user-select: none;
                                            -moz-user-select: none;
                                            -ms-user-select: none;
                                            user-select: none;
                                          }

                                          .container-label input {
                                            position: absolute;
                                            opacity: 0;
                                            height: 0;
                                            width: 0;
                                          }
                                          
                                          .checkmark {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            height: 25px;
                                            width: 25px;
                                            background-color: transparent;
                                            border: 1px solid #927c60;
                                          }
                                        
                                          
                                          .checkmark:after {
                                            content: "";
                                            position: absolute;
                                            display: none;
                                          }
                                          
                                          /* Show the checkmark when checked */
                                          .container-label input:checked ~ .checkmark:after {
                                            display: block;
                                          }
                                          
                                          /* Style the checkmark/indicator */
                                          .container-label .checkmark:after {
                                            left: 9px;
                                            top: 5px;
                                            width: 5px;
                                            height: 10px;
                                            border: 1px solid #927c60;
                                            border-width: 0 3px 3px 0;
                                            -webkit-transform: rotate(45deg);
                                            -ms-transform: rotate(45deg);
                                            transform: rotate(45deg);
                                          }

                                        
                                    }
                                }
                            }

                            .col-2 {
                                width: 50%;
                                box-sizing: border-box;
                                padding: 0 5px 5px;

                                @media only screen and (max-width: 576px) {
                                    width: 100%;
                                }
                            }

                            .col-3 {
                                width: 100%;
                                display: flex;
                                // flex-wrap: wrap;
                                box-sizing: border-box;
                                padding: 0 5px 5px;

                                .wrapper {
                                    width: 10%;
                                    text-align: center;
                                    margin: auto;

                                    span {
                                        color: #808080;
                                    }

                                }

                                @media only screen and (max-width: 576px) {
                                    justify-content: center;
                                    button {
                                        width: 45%;
                                    }
                                }
                            }

                            @media only screen and (max-width: 576px) {
                                .col-1, .col-2, .col-3 {
                                    padding: 0 5px 15px;
                                }
                            }

                            label {

                                // padding-bottom: 10px;
                                span {
                                    margin: 0;
                                    color: #808080;

                                    &:last-child {
                                        font-family: gill-sans-nova, sans-serif;
                                        font-weight: 400;
                                    }
                                }
                            }

                            button {
                                border: 1px solid #927c60;
                                width: 25%;
                                height: 30px;
                                outline: none;
                                font-size: 14px;

                                &.btn-upload {
                                    color: #808080;
                                    font-weight: 400;
                                    background: transparent;
                                }

                                &.btn-submit {
                                    background: #927c60;
                                    color: #333333;
                                }
                            }

                            input[type=text] {
                                width: 100%;
                                font-family: gill-sans-nova, sans-serif;
                                font-weight: 400;
                                font-size: 16px;
                                border: 1px solid #927c60;
                                color: #927c60;
                                box-sizing: border-box;
                                height: 30px;
                                padding: 0 0 0 10px;
                                outline: none;
                                background: transparent;
                                -webkit-appearance: none;
                                border-radius: 0;
                            }
                        }
                    }
                }

                @media only screen and (max-width: 576px) {

                    .box-left,
                    .box-right {
                        width: 100%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 576px) {
        height: 100%;
        overflow: auto;

        .container {
            padding: 80px 20px 80px;
            height: 100%;
        }
    }
}