.section-homeanim {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    padding: 40px;
    box-sizing: border-box;

    @media only screen and (max-width: 992px) {
        height: calc(var(--vh, 1vh) * 100);
    }

    @media only screen and (max-width: 576px) {
        padding: 20px;
    }

    p {
        white-space: pre;
        font-size: 18px;
        text-align: center;
        color: #fff;
        font-weight: 300;
        letter-spacing: 1.2px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 1;
        margin: 0;
        transition: filter 1.2s ease;

        @media only screen and (max-width: 576px) {
            font-size: 12px;
            white-space: nowrap;
        }
    }

    .box {
        width: 100%;
        // padding: 30px;
        z-index: 1;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        

        svg {
            z-index: 1;
            width: 40%;
            fill: #fff;
            filter: blur(8px);
            opacity: 0;
            transition: filter 1.2s ease;
        }
    }

    @media only screen and (max-width: 576px) {

        padding: 20px;
        .box {
            width: 100%;
            padding: 0;
            svg {
                width: 70%;
            }
        }
    }

    .container {
        z-index: 2;
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        h1 {
            color: #927C60;
            font-size: 1.5em;
            opacity: 0;
            text-align: center;
            z-index: 1;
            margin: 0;
            
            > span {
                display: inline-block;
            }
        }
    }

    .split{
        text-align:center;
        .characters{
			display: inline-block;
        }
        .separate{
			position: absolute;
		}
    }

    video#video-cover {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        background-position: center center;
        background-size: contain;
        object-fit: cover;
        z-index: 1;
        opacity: 1;
        outline: none;
    }
}

@media only screen and (max-width: 992px) and (orientation: landscape) {
    .section-homeanim {
        padding: 20px;
        p {
            font-size: 15px !important;
        }

        h1 {
            font-size: 1em !important;
        }
    }
}