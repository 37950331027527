.grid-work {
    display: grid;
    // grid-template-columns: repeat(5, 1fr);
    // grid-auto-rows: calc(100vh / 3);
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: calc(70vw / 4);
    width: 100%;
    // height: 100vh;
    // position: absolute;

    @media only screen and (max-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: calc(100vw / 4);
        height: 100%;

    }

    @media only screen and (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: calc(100vh / 4);
        height: 100%;

    }

    .thumbnail {
        width: 100%;
        height: 100%;
        position: relative;
        background: #000;
        transition: all .6s ease-out;
        opacity: 0;
        animation: fadeIn .6s forwards .2s;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: grayscale(100%);
            transition: all .5s ease-out;

            &:hover {
                filter: grayscale(0%);
                opacity: 0;
            }
        }

        button {
            width: 100%;
            height: 100%;
            background-color: #000;
            border: none;
            outline: none;

            a {
                font-size: 4vw;
                text-decoration: none;
                color: #927c60;
                font-family: aw-conqueror-didot, serif;
                white-space: pre-line;

                @media only screen and (max-width: 1200px) {
                    font-size: 5vw;
                }

                @media only screen and (max-width: 576px) {
                    font-size: 10vw;
                }
            }
        }
    }
}