.portal {
    background: #000;
    width: 100%;
    // width: 80%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 40px;
    box-sizing: border-box;
    z-index: 5;
    transition: opacity .5s ease-out;
    pointer-events: none;

    @media only screen and (max-width: 768px) {

        height: 100vh;
        // height: calc(var(--vh, 1vh) * 100);
    }

    // video {
    //     z-index: -1 !important;
    // }

    &.active {
        opacity: 1;
        pointer-events: all;
    }

    p {
        margin: 0;
        font-size: 13px;
        color: #989898;
        font-weight: 400;
    }

    h1 {
        color: #927C60 !important;
        margin: 0;
        margin-bottom: 10px;
    }

    .text-title {
        font-size: 3.5em;
    }

    .text-company {
        font-size: 2.5em;
    }

    p.text-description {
        text-align: justify;
        font-size: 14px;

    }


    .container-wrapper {
        width: 100%;
        height: 100%;
        margin: auto;
        position: fixed;
        top: 0;
        left: 0;
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        &.fade {
            transition: opacity .3s ease;
            opacity: 0;
        }

        @media only screen and (max-width: 576px) {
            padding: 20px 20px 80px;
        }

        @media only screen and (max-width: 992px) and (orientation: landscape) {
            overflow-y: scroll;
        }
    }

    .wrapper-1 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        position: relative;

        @media only screen and (max-width: 576px) {
            width: 80%;
        }

        .container-text {
            h1 {
                margin: 0;
            }
    
            p {
                text-transform: uppercase;
            }
        }
    }

    .wrapper-container {
        // width: 100%;
        width: 90%;
        margin: auto;
        position: relative;

        @media only screen and (max-width: 1024px)  {
            width: 100%;
        }

        .wrapper-portal {
            display: flex;
            align-items: center;
            height: 100%;
            position: relative;


            .container-left {
                width: 90%;
                margin: auto;
                padding: 20px;
                position: relative;
                
                @media only screen and (max-width: 1200px) {
                    width: 100%;
                }

                @media only screen and (max-width: 1024px) and (orientation: portrait) {
                    padding: 0;
                }
            }

            .container-right {
                width: 40%;
                padding: 0 40px;

                @media only screen and (max-width: 1024px) {
                    padding: 40px;
                    width: 100%;
                }

                @media only screen and (max-width: 576px) {
                    padding: 40px 0;
                }
            }
        }
        
    }

    .wrapper-btn {
        display: flex;
        justify-content: center;
        align-items: center;
    
        .count {
            display: flex;
            margin: 0 25px;
            align-items: center;
    
            span {
                width: 5px;
                height: 1px;
                background-color: #989898;
                // position: relative;
                margin: 0 10px;
            }
        }
    
        div.btn {
            content: '';
            background-position: center;
            background-repeat: no-repeat;
            height: 20px;
            width: 20px;
            display: block;
    
            &.prev {
                background-image: url(/assets/ic-left.svg);
            }
    
            &.next {
                background-image: url(/assets/ic-right.svg);
            }
        }
    }

    button.closePortal {
        border: none;
        outline: none;
        position: fixed;
        right: 40px;
        width: 28px;
        height: 28px;
        background: url(/assets/plus.svg) no-repeat center;
        background-color: transparent;
        z-index: 5;

        @media only screen and (max-width: 576px) {
            // top: 10px;
            right: 20px;
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
    
        to {
            opacity: 1;
        }
    }
    
    @keyframes fadeOut {
        from {
            opacity: 1;
        }
    
        to {
            opacity: 0;
        }
    }
    
    .hidden {
        overflow: hidden;
    
        h1 {
            opacity: 1;
        }
    }
}